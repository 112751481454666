window.Popper = require('popper.js').default;

window.$ = window.jQuery = require('jquery');

window.changeMainImage = function (url) {
    $('.mainimage img').attr('src', url);
    return false;
};

window.lightbox = require('lightbox2/dist/js/lightbox');

lightbox.option({
    'showImageNumberLabel': false
});

$(function() {
    $('#product-reviews .list .more-reviews a.show').on('click', function (e) {
        e.preventDefault();
        $('#product-reviews .list .item.hide').slideDown();
        $(this).hide();
        $('#product-reviews .list .more-reviews a.hide').show();
    });
    $('#product-reviews .list .more-reviews a.hide').on('click', function (e) {
        e.preventDefault();
        $('#product-reviews .list .item.hide').slideUp();
        $(this).hide();
        $('#product-reviews .list .more-reviews a.show').show();
    });
    $(document).on('click', '#faq button', function(e) {
        e.preventDefault();
        $('#faq button').attr('aria-expanded', false);
        $(this).attr('aria-expanded', true);
    });
    /*if (localStorage.getItem('customer_not_new') === null && localStorage.getItem('customer') === null && localStorage.getItem('dont_show_first_order_banner') === null) {
        $('#firstorderbanner').addClass('show');
        $('.mainmenu-dropdown-wrapper').addClass('withFirstOrderBanner');
        $('.catalog-menu__btn').on('click', function () {
            $('.mainmenu-dropdown-wrapper').addClass('withFirstOrderBanner');
        });
        $('#firstorderbanner .close').on('click', function (e) {
            e.preventDefault();
            $('#firstorderbanner').removeClass('show');
            localStorage.setItem('dont_show_first_order_banner', 1);
        });
    }*/
});
