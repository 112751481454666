window._ = require('lodash');

//Jquery
import './jquery';

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

require('es6-promise/auto');

window.Vue = require('vue');

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import router from './router';

import store from './store';

Vue.use(require('vue-cookies'));
Vue.$cookies.config('7d');

import './sovet';

//Текстовое поле
Vue.component('input-text', require('./components/ui/InputText.vue').default);
//Товары в категории
Vue.component('products', require('./components/ui/products/Products.vue').default);
//Варианты товара
Vue.component('variants', require('./components/ui/products/Variants.vue').default);
//Похожие товары
Vue.component('similars', require('./components/ui/products/Similars.vue').default);
//Форма добавления отзыва для товара
Vue.component('add-product-review', require('./components/ui/products/AddProductReview.vue').default);
//Модальное окно сообщить о поступении товара
Vue.component('modal-product-preorder', require('./components/ui/products/ModalProductPreorder.vue').default);
//Кнопка купить
Vue.component('buy-button', require('./components/ui/buttons/BuyButton.vue').default);
//Модальное окно при добавлении товара в корзину
Vue.component('modal-add-cart', require('./components/ui/cart/ModalAddCart.vue').default);
//Виджет корзины в шапке
Vue.component('cart-widget', require('./components/ui/cart/CartWidget.vue').default);
//Корзина
Vue.component('cart', require('./components/ui/cart/Cart.vue').default);
//Кнопка выбора локации
Vue.component('location-button', require('./components/ui/location/LocationButton.vue').default);
//Модальное окно выбора локации
Vue.component('modal-location', require('./components/ui/location/ModalLocation').default);
//Оформление заказа
Vue.component('checkout', require('./components/ui/checkout/Checkout.vue').default);
//Лоадер на весь сайт
Vue.component('global-loading', require('./components/ui/loading/GlobalLoading.vue').default);
//Авторизация
Vue.component('modal-login', require('./components/ui/login/ModalLogin.vue').default);
//Виджет доставки
Vue.component('product-delivery', require('./components/ui/product-delivery/ProductDelivery.vue').default);
//Страница доставки
Vue.component('delivery-list', require('./components/ui/delivery-list/DeliveryList.vue').default);
//Тинькофф оплата
Vue.component('tinkoff-pay', require('./components/tinkoff/Pay.vue').default);
//Товары в блоге
Vue.component('products-in-blog', require('./components/ui/blog/ProductsInBlog.vue').default);
//FAQ
Vue.component('faq', require('./components/ui/faq/Faq.vue').default);

const app = new Vue({
    router,
    store,
    el: '#app',
    data: {
        mainmenuDropdown: false,
        mobilemenu: false,
        mobilesearch: false,
    },
    methods: {
        showMainMenuDropdown() {
            this.mainmenuDropdown = !this.mainmenuDropdown;
        },
        showMobileMenu() {
            this.mobilemenu = !this.mobilemenu;
        },
        hideMobileMenu() {
            this.mobilemenu = false;
        },
        showMobileSearch() {
            this.mobilesearch = !this.mobilesearch;
        },
        hideMobileSearch() {
            this.mobilesearch = false;
        }
    }
});
