<template>
    <div class="show-modal-preorder" v-if="show">
        <div class="modal-overlay modal-preorder-overlay" @click.prevent="hideModal()"></div>
        <div class="modal modal-preorder">
            <a href="#" class="modal-close" @click.prevent="hideModal()"></a>
            <div class="modal-header">
                <div class="title">Сообщить о поступлении товара</div>
            </div>
            <div class="modal-body" v-if="!success">
                <div class="form-group">
                    <div class="desc">Введите номер телефона для СМС уведомления.</div>
                    <input type="text" class="input-text" id="login_phone" placeholder="+7(___)___-__-__" v-maska="'+7(###)###-##-##'" v-model="phone" autocomplete="off">
                </div>
                <div class="form-group">
                    <input type="submit" value="Сообщить о поступлении" class="green-bordered-button" @click.prevent="submit()">
                </div>
                <div class="rules">
                    Нажимая кнопку "Сообщить о поступлении" я соглашаюсь с условиями обработки персональных данных и <a href="https://docs.google.com/document/d/1YypIAVQbzKMdlYKfG2UUyquQcwjcNgi1K7Y7OzN9r2k/edit?usp=sharing" target="_blank">политикой конфиденциальности</a>.
                </div>
            </div>
            <div class="modal-success" v-if="success">
                <div class="icon"><i class="far fa-check-circle"></i></div>
                <div class="text">Спасибо!<br/>Как только товар появится в продаже, мы сразу оповестим Вас.</div>
                <div class="button">
                    <a href="#" class="close" @click.prevent="hideModal()">Закрыть</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { maska } from 'maska'
    import { required, minLength } from 'vuelidate/lib/validators'

    export default {
        data() {
            return {
                phone: null,
                success: false
            };
        },
        props: {
            'show': Boolean,
            'product_name': String
        },
        directives: {
            maska
        },
        validations() {
            return {
                phone: {
                    required,
                    minLength: minLength(16)
                }
            }
        },
        methods: {
            submit() {
                this.$store.dispatch('Shop/showGlobalLoading');

                this.$v.$touch();

                if (this.$v.$invalid) {
                    this.$store.dispatch('Shop/hideGlobalLoading');
                } else {
                    axios.post('/api/frontend/preorder/add', {
                        'phone': this.phone,
                        'product_name': this.product_name
                    })
                    .then((response) => {
                        if (response.data === 'ok') {
                            this.success = true;
                        }
                        this.$store.dispatch('Shop/hideGlobalLoading');
                    })
                    .catch(error => {
                        console.log(error);
                        this.$store.dispatch('Shop/hideGlobalLoading');
                    });
                }
            },
            hideModal() {
                this.$store.dispatch('Shop/hideModalProductPreorder');
            }
        }
    }
</script>