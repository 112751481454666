<template>
    <a href="#" class="buy-button" @click.prevent="addToCart()" :class="{'loaded': isLoading, 'success': isSuccess}"><span>{{ textButton }}</span></a>
</template>

<script>
    export default {
        data() {
            return {
                textButton: 'В корзину',
                isLoading: false,
                isSuccess: false,
            };
        },
        props: {
            product_id: Number,
            variant_id: Number,
            quantity: Number,
            name: String,
            unit_name: String,
            price: Number,
            total_price: Number,
            discount: Number,
            discount_type: String,
            image_url: String,
            slug: String
        },
        methods: {
            addToCart() {
                this.isLoading = true;

                let product = {
                    product_id: this.product_id,
                    variant_id: this.variant_id,
                    quantity: this.quantity,
                    name: this.name,
                    unit_name: this.unit_name,
                    price: this.price,
                    total_price: this.total_price,
                    discount: this.discount,
                    discount_type: this.discount_type,
                    image_url: this.image_url,
                    slug: this.slug
                };

                this.$store.dispatch('Shop/addToCart', product);

                setTimeout(() => {
                    this.isLoading = false;
                    this.isSuccess = true;
                    this.textButton = 'В корзине';
                }, 500);
            },
        }
    }
</script>
