<template>
    <div class="show-modal-login" v-if="show">
        <div class="modal-overlay modal-login-overlay" @click.prevent="hideModalLogin()"></div>
        <div class="modal modal-login">
            <a href="#" class="modal-close" @click.prevent="hideModalLogin()"></a>
            <div class="modal-header">
                <div class="title">Вход в личный кабинет</div>
            </div>
            <div class="modal-body">
                <div class="step1" v-if="step1">
                    <div class="form-group">
                        <div class="desc">Введите номер телефона, который Вы указывали при оформлении заказа. На данный номер придет СМС с кодом входа в личный кабинет.</div>
                        <input type="text" class="input-text" id="login_phone" placeholder="+7(___)___-__-__" v-maska="'+7(###)###-##-##'" v-model="phone" autocomplete="off">
                    </div>
                    <div class="form-group submit">
                        <div v-if="countdown === 0">
                            <input type="submit" value="Войти" class="green-bordered-button" @click.prevent="submitStep1()">
                        </div>
                        <div v-else-if="countdown > 0">
                            <span class="green-bordered-button">Войти через {{ countdown }} сек.</span>
                        </div>
                    </div>
                    <div class="modal-login-error" v-if="error">
                        {{error}}
                    </div>
                </div>
                <div class="step2" v-if="step2">
                    <div class="form-group">
                        <input type="text" class="input-text" placeholder="Введите 4 цифры" v-maska="'####'" v-model="code" autocomplete="off">
                    </div>
                    <div class="noCustomer" v-if="noCustomer === true">
                        <div class="noCustomer-text">Неверно введен номер телефона или сектретный код!</div>
                        <div class="noCustomer-link"><a href="#" @click.prevent="backStep1()">Отправить код повторно.</a></div>
                    </div>
                    <div class="form-group">
                        <input type="submit" value="Подтвердить" class="green-bordered-button" @click.prevent="submitStep2()">
                    </div>
                    <div class="test-period" v-if="show_test_period_text">
                        <i class="fas fa-info-circle"></i>&nbsp&nbspНаблюдаются сбои с приходом СМС сообщений с кодом. Приносим свои извинения за возможные неудобства.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { maska } from 'maska'

    export default {
        data() {
            return {
                step1: true,
                step2: false,
                noCustomer: false,
                phone: null,
                code: null,
                error: null,
                countdown: window.localStorage.getItem('countdown') ? parseInt(window.localStorage.getItem('countdown')) : 0,
                show_test_period_text: true
            };
        },
        props: ['show'],
        directives: {
            maska
        },
        mounted() {
            this.countdownTimer();
        },
        methods: {
            submitStep1() {
                if (this.phone !=null && this.phone.length === 16) {
                    axios.post('/api/customer/login/sms', {
                            'phone': this.phone.replace(/\+/g, '')
                        })
                        .then((response) => {
                            if (response.data.message === 'ok') {
                                this.step1 = false;
                                this.step2 = true;
                                this.countdown = 180;
                                this.countdownTimer();
                            } else if (response.data.message === 'no user') {
                                this.error = 'Неверный номер телефона';
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                } else {
                    this.error = 'Введите номер телефона';
                }
            },
            submitStep2() {
                if (this.code !=null && this.code.length === 4) {
                    this.show_test_period_text = false;
                    axios.post('/api/customer/login/code', {
                        'phone': this.phone.replace(/\+/g, ''),
                        'code': this.code
                    })
                    .then((response) => {
                        if (response.data.message === 'No customer') {
                            this.noCustomer = true;
                        } else if (response.data.phone) {
                            let customer = response.data;
                            window.localStorage.setItem('customer', JSON.stringify(customer));
                            window.location.reload();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
            },
            hideModalLogin() {
                this.$store.dispatch('Shop/hideModalLogin');
            },
            countdownTimer() {
                if (this.countdown > 0) {
                    setTimeout(() => {
                        this.countdown -= 1;
                        this.countdownTimer();
                        window.localStorage.setItem('countdown', this.countdown);
                    }, 1000)
                }
            },
            backStep1() {
                this.phone = null;
                this.code = null;
                this.error = null;
                this.step1 = true;
                this.step2 = false;
                this.noCustomer = false;
            }
        }
    }
</script>
