<template>
    <div class="container" id="cart" v-if="$store.state.Shop.cart.length > 0">
        <div class="row">
            <div class="col-12">
                <h1 v-if="$vssWidth < 575">В корзине {{ totalCount }} {{ countProdsTitle }}</h1>
                <h1 v-else>В вашей корзине {{ totalCount }} {{ countProdsTitle }}:</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-7 col-xl-8">
                <ul class="list mobile" v-if="$vssWidth < 575">
                    <li class="no-gutters" v-for="item in $store.state.Shop.cart" :key="item.variant_id">
                        <div class="col-3">
                            <div class="image">
                                <img :src="'/storage/' + item.image_url" alt="" v-if="item.image_url">
                                <img src="/images/frontend/no-image.png" alt="" v-else>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="content">
                                <div class="name">{{ item.name }}</div>
                                <div class="price">
                                    <div class="price__old" v-if="item.discount > 0">{{ item.price * item.in_cart_quantity }} &#8381;</div>
                                    <div class="price__now" :class="{'red': item.discount > 0}">{{ item.in_cart_total_price }}</div>
                                    <div class="price_currency" :class="{'red': item.discount > 0}"> &#8381;</div>
                                </div>
                                <div class="quantity-block">
                                    <div class="quantity-price">
                                        <div class="overlay">
                                            <div class="quantity">
                                                <div class="buttons">
                                                    <a href="#" class="minus" @click.prevent="minusQuantity(item.variant_id)" v-if="item.in_cart_quantity > 1">-</a>
                                                    <a href="#" class="minus disable" @click.prevent="" v-else>-</a>
                                                    <span class="num">{{ item.in_cart_quantity }}</span>
                                                    <a href="#" class="plus" @click.prevent="plusQuantity(item.variant_id)">+</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="overlay">
                                            <div class="short_name">
                                                {{ item.in_cart_quantity }} x {{ item.unit_name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="trash"><a href="#" class="btn-trash" @click.prevent="removeFromCart(item)"><i class="far fa-trash-alt"></i></a></div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="list" v-else>
                    <li v-for="item in $store.state.Shop.cart" :key="item.variant_id">
                        <div class="row">
                            <div class="col-3">
                                <div class="image">
                                    <a :href="'/product/' + item.slug + '/'">
                                        <img :src="'/storage/' + item.image_url" alt="" v-if="item.image_url">
                                        <img src="/images/frontend/no-image.png" alt="" v-else>
                                    </a>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="content">
                                    <div class="name"><a :href="'/product/' + item.slug + '/'">{{ item.name }}</a></div>
                                    <div class="quantity-block">
                                        <div class="quantity-price">
                                            <div class="overlay">
                                                <div class="quantity">
                                                    <div class="buttons">
                                                        <a href="#" class="minus" @click.prevent="minusQuantity(item.variant_id)" v-if="item.in_cart_quantity > 1">-</a>
                                                        <a href="#" class="minus disable" @click.prevent="" v-else>-</a>
                                                        <span class="num">{{ item.in_cart_quantity }}</span>
                                                        <a href="#" class="plus" @click.prevent="plusQuantity(item.variant_id)" v-if="item.quantity > 0 && item.in_cart_quantity < item.quantity">+</a>
                                                        <a href="#" class="plus disable" @click.prevent="" v-else-if="item.quantity > 0 && item.in_cart_quantity === item.quantity">+</a>
                                                        <a href="#" class="plus" @click.prevent="plusQuantity(item.variant_id)" v-else>+</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="overlay">
                                                <div class="short_name">
                                                    {{ item.in_cart_quantity }} x {{ item.unit_name }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 price">
                                <div class="text">Сумма</div>
                                <div class="num">
                                    <div class="price">
                                        <div class="price__old" v-if="item.discount > 0">{{ item.price * item.in_cart_quantity }} &#8381;</div>
                                        <div>
                                            <div class="price__now" :class="{'red': item.discount > 0}">{{ item.in_cart_total_price }}</div>
                                            <div class="price_currency" :class="{'red': item.discount > 0}"> &#8381;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1 trash"><a href="#" class="btn-trash" @click.prevent="removeFromCart(item)"><i class="far fa-trash-alt"></i></a></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-lg-5 col-xl-4">
                <div class="total sticky-top">
                    <div class="promocode active row no-gutters" v-if="this.$store.state.Shop.cartPromocode.name">
                        <div class="col-6">
                            <div class="text">Промокод</div>
                        </div>
                        <div class="col-6">
                            <div class="current">
                                {{ $store.state.Shop.cartPromocode.name }} <a href="#" @click.prevent="removePromocode()"> <i class="far fa-times-circle"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="promocode" v-else>
                        <form action="" class="no-gutters">
                            <div class="col-7">
                                <input type="text" class="form-control" v-model="enterPromocode" placeholder="Промокод на скидку" />
                            </div>
                            <div class="col-5">
                                <input type="submit" class="btn btn-primary" value="Применить" @click.prevent ="addPromocode()" />
                            </div>
                        </form>
                        <div class="errored" v-if="errorPromocode">Промокод введен неверно или его действие закончилось. Попробуйте еще раз.</div>
                    </div>
                    <div class="total-sum">
                        <div class="row no-gutters total-sum__products">
                            <div class="col-6"><div class="text">Товаров</div></div>
                            <div class="col-6"><div class="num">{{ totalPriceWithoutSales }} &#8381;</div></div>
                        </div>
                        <div class="row no-gutters total-sum__sale" v-if="totalSaleInCart > 0">
                            <div class="col-6"><div class="text">Скидка на товары</div></div>
                            <div class="col-6"><div class="num red">-{{ totalSaleInCart }} &#8381;</div></div>
                        </div>
                        <div class="row no-gutters total-sum__total">
                            <div class="col-6"><div class="text">Итого</div></div>
                            <div class="col-6"><div class="num">{{ totalPriceInCart }} &#8381;</div></div>
                        </div>
                    </div>
                    <div class="delivery-list" v-if="region_kladr_id !== 0">
                        <div class="free" v-if="total_price_in_cart < from_free_delivery_pickup">Добавьте товаров на <strong>{{ from_free_delivery_pickup - total_price_in_cart }} &#8381;</strong><br/>и получите <span>бесплатную доставку в ПВЗ!</span></div>
                        <div class="delivery-list__title">Варианты доставки:</div>
                        <div class="variants">
                            <div class="region">Ваш регион: <LocationButton></LocationButton></div>
                            <div class="item" v-if="todoor_price > 0 && todoor_enable === 1">
                                <span v-if="total_price_in_cart < from_free_delivery || todoor_always_paid === 1">Курьером — {{ todoor_price }} &#8381;</span>
                                <span v-else-if="total_price_in_cart >= from_free_delivery && todoor_always_paid === 0">Курьером — <span class="green">Бесплатно</span></span>
                            </div>
                            <div class="item" v-if="pickup_price > 0 && pickup_enable === 1">
                                <span v-if="total_price_in_cart < from_free_delivery_pickup">В пункт выдачи — {{ pickup_price }} &#8381;</span>
                                <span v-else>В пункт выдачи — <span class="green">Бесплатно</span></span>
                                <div class="grey_desc">(Boxberry, СДЭК на выбор)</div>
                            </div>
                            <div class="item" v-if="post_price > 0 && post_enable === 1">
                                <span v-if="total_price_in_cart < from_free_delivery">Почтой России — {{ post_price }} &#8381;</span>
                                <span v-else>Почтой России — <span class="green">Бесплатно</span></span>
                            </div>
                            <div class="item" v-if="office_enable === 1">
                                <span>Самовывоз — <span class="green">Бесплатно</span></span>
                            </div>
                        </div>
                    </div>
                    <div class="min-order" v-if="total_price_in_cart < min_order">Минимальная сумма заказа {{ min_order }} &#8381;</div>
                    <span class="btn-checkout-no" v-if="total_price_in_cart < min_order">Перейти к оформлению</span>
                    <a href="/checkout/" class="btn-checkout" v-if="total_price_in_cart >= min_order">Перейти к оформлению</a>
                </div>
            </div>
        </div>
    </div>
    <div class="container" id="cart" v-else>
        <div class="row">
            <div class="col-12">
                <h1>Ваша корзина пуста</h1>
            </div>
        </div>
    </div>
</template>

<script>
    import VueScreenSize from 'vue-screen-size';
    import LocationButton from './../location/LocationButton.vue'

    export default {
        mixins: [VueScreenSize.VueScreenSizeMixin],
        data() {
            return {
                enterPromocode: '',
                promocode: null,
                errorPromocode: false,
                region_kladr_id: 0,
                todoor_price: 0,
                pickup_price: 0,
                post_price: 0,
                from_free_delivery: 0,
                from_free_delivery_pickup: 0,
                todoor_enable: 0,
                pickup_enable: 0,
                post_enable: 0,
                office_enable: 0,
                total_price_in_cart: 0,
                min_order: 0,
                todoor_always_paid: 0
            };
        },
        components: {
            LocationButton
        },
        mounted() {
            this.$store.dispatch('Shop/updateCart');
            this.region_kladr_id = this.$store.state.Shop.location.data.region_kladr_id;
            if (this.region_kladr_id > 0) {
                this.getDelivery();
            }
        },
        methods: {
            getDelivery() {
                axios.get('/api/frontend/delivery/get', {
                    params: {
                        'kladr_id': this.region_kladr_id,
                    }
                })
                    .then((response) => {
                        this.pickup_price = response.data.pickup_price;
                        this.todoor_price = response.data.todoor_price;
                        this.post_price = response.data.post_price;
                        this.todoor_enable = response.data.todoor_enable;
                        this.pickup_enable = response.data.pickup_enable;
                        this.post_enable = response.data.post_enable;
                        this.office_enable = response.data.office_enable;
                        this.from_free_delivery = response.data.from_free_delivery;
                        this.from_free_delivery_pickup = response.data.from_free_delivery_pickup;
                        this.min_order = response.data.min_order;
                        this.todoor_always_paid = response.data.todoor_always_paid;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            removeFromCart(product) {
                this.$store.dispatch('Shop/removeFromCart', product);
            },
            plusQuantity(variant_id) {
                this.$store.dispatch('Shop/plusQuantityInCart', variant_id);
            },
            minusQuantity(variant_id) {
                this.$store.dispatch('Shop/minusQuantityInCart', variant_id);
            },
            addPromocode() {
                axios.get('/api/frontend/promocode/get', {
                    params: {
                        'promocode': this.enterPromocode
                    }
                    })
                    .then((response) => {
                        if(response.data.message) {
                            this.errorPromocode = true;
                        } else {
                            this.errorPromocode = false;
                            sessionStorage.setItem('cartPromocode', JSON.stringify(response.data));
                            this.$store.dispatch('Shop/addPromocode', response.data);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            removePromocode() {
                window.sessionStorage.removeItem('cartPromocode');
                window.location.reload();
            }
        },
        computed: {
            totalPriceInCart() {
                let total = 0;

                for (let item of this.$store.state.Shop.cart) {
                    total += item.in_cart_total_price;
                }

                this.total_price_in_cart = total;

                return total;
            },

            totalSaleInCart() {
                let total = 0;

                for (let item of this.$store.state.Shop.cart) {
                    total += item.price * item.in_cart_quantity - item.in_cart_total_price;
                }

                return total;
            },

            totalPriceWithoutSales() {
                let total = 0;

                for (let item of this.$store.state.Shop.cart) {
                    total += item.price * item.in_cart_quantity;
                }

                return total;
            },

            totalCount() {
                let total = 0;

                for (let item of this.$store.state.Shop.cart) {
                    total += item.in_cart_quantity;
                }

                return total;
            },

            countProdsTitle() {
                let number = 0;

                for (let item of this.$store.state.Shop.cart) {
                    number += item.in_cart_quantity;
                }

                let cases = [2, 0, 1, 1, 1, 2];
                let titles = ['товар', 'товара', 'товаров'];

                return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
            }
        }
    }
</script>
