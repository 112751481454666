<template>
    <div class="container-fluid similar-products">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="part-title">
                        <div class="part-title__name">{{ title }}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-4 col-xxl-4"  v-for="(product,index) in products" :key="product.id">
                    <div class="product-vertical nomargin">
                        <div class="product-vertical__image">
                            <a :href="'/product/' + product.slug + '/'">
                                <img :src="'/storage/' + product.mainimage" v-if="product.mainimage">
                                <img src="/images/frontend/no-image.png" v-else>
                            </a>
                            <div class="sticker hot" v-if="product.discount > 29"><i class="fas fa-fire"></i> Горячая цена</div>
                            <div class="sticker liquidation" v-else-if="product.discount > 49"><i class="fas fa-fire"></i> Ликвидация</div>
                            <div class="sticker exclusively" v-else-if="product.discount < 30 && product.is_exclusively === 1"><i class="fas fa-crown"></i> Эксклюзивно</div>
                            <div class="sticker best" v-else-if="product.discount < 30 && product.is_best === 1"><i class="fas fa-kiss-wink-heart"></i> Рекомендуем</div>
                            <div class="sticker hit" v-else-if="product.discount < 30 && product.is_hit === 1"><i class="fas fa-star"></i> Хит продаж</div>
                            <div class="sticker new" v-else-if="product.discount < 30 && product.is_new === 1"><i class="fas fa-leaf"></i> Новинка</div>
                            <div class="sticker antiprice" v-else-if="product.discount < 30 && product.is_antiprice === 1"><i class="fas fa-sun"></i> Держим цену</div>
                        </div>
                        <div class="product-vertical__type">{{ product.type.name }}</div>
                        <div class="product-vertical__name" v-if="product.short_name"><a :href="'/product/' + product.slug + '/'">{{ product.short_name }}</a></div>
                        <div class="product-vertical__name" v-else><a :href="'/product/' + product.slug + '/'">{{ product.name }}</a></div>
                        <div class="product-vertical__desc">
                            {{ product.short_description_truncated }}. <a :href="'/product/' + product.slug">Подробнее...</a>
                        </div>
                        <variants :variants="product.variants" :position="'category'"></variants>
                        <a href="/favorite" class="product-vertical__favorite">
                            <svg>
                                <use xlink:href="/images/frontend/icons.svg#favorite" />
                            </svg>
                        </a>
                        <div class="product-vertical__sales" v-if="product.discount > 0 && product.discount_type === 'percents'">
                            <div class="product-sale" :class="{red: product.discount > 29, yellow: product.discount > 0 && product.discount < 30}">-{{ product.discount }}%</div>
                        </div>
                        <div class="product-vertical__sales" v-if="product.discount > 0 && product.discount_type === 'numbers'">
                            <div class="product-sale">-{{ product.discount }}&#8381;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                products: []
            }
        },
        props: {
            products_ids: Array,
            title: String
        },
        mounted() {
            this.getProducts();
        },
        methods: {
            getProducts() {
                axios
                    .get('/api/frontend/products/get', {
                        params: {
                            "products_ids": this.products_ids,
                            "random": 1,
                            "take": 3
                        }
                    })
                    .then((response) => {
                        this.products = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }
</script>
