<template>
    <div class="container-fluid" id="faq">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="h1">Часто задаваемые вопросы</div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12" v-for="(item, index) in faq" :key="item.id">
                    <div class="faq-item">
                        <button :id="'question-' + index" aria-expanded="false">
                            <span class="question-title">{{ item.question }}</span>
                            <span class="icon" aria-hidden="true"></span>
                        </button>
                        <div class="answer" v-html="item.answer" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
          return {
              region_kladr_id: null,
              faq: null
          }
        },
        props: [
            'category_id'
        ],
        mounted() {
            this.region_kladr_id = this.$store.state.Shop.location.data.region_kladr_id;
            if (this.region_kladr_id.length > 0) {
                this.get();
            }
        },
        methods: {
            get() {
                let self = this;
                axios.get('/api/frontend/faq/get', {
                    params: {
                        'region_kladr_id': self.region_kladr_id,
                        'category_id': self.category_id
                    }
                })
                .then((response) => {
                    self.faq = response.data;
                    self.loading = false;
                })
                .catch(error => {
                    console.log(error);
                });
            },

        }
    }
</script>
