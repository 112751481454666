<template>
    <div class="input-text" :class="{focused: focused}" v-click-outside="disableFocused" @click="enableFocused">
        <label>Улица</label>
        <input type="text" />
    </div>
</template>
<script>
    import ClickOutside from 'vue-click-outside'

    export default {
        data() {
            return {
                focused: false,
            };
        },
        methods: {
            enableFocused() {
                this.focused = true;
            },
            disableFocused() {
                this.focused = false;
            },
        },
        directives: {
            ClickOutside
        }
    }
</script>