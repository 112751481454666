<template>
    <div class="container" id="checkout">
        <div class="row block">
            <div class="col-12">
                <h1>Оформление заказа</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-7 col-xl-8">
                <div class="block">
                    <h2>Город получения</h2>
                    <div class="delivery-location">
                        <location-button></location-button>
                    </div>
                </div>
                <div class="block">
                    <h2 :class="{ 'error': $v.checkout.delivery.dtype.$error }">
                        <span v-if="checkout.delivery.dtype">Способ доставки</span>
                        <span v-else>Выберите способ доставки</span>
                    </h2>
                    <label class="input-radio" @click="changeDeliveryType('todoor')" v-if="delivery.todoor_enable === 1">
                        <span class="text">
                            <span>Курьером {{ delivery.todoor_tk }} — </span>
                            <span v-if="delivery.todoor_price === 0"><span class="green-color">Бесплатно</span></span>
                            <span v-else>{{ delivery.todoor_price }} &#8381;</span>
                        </span>
                        <span class="date">
                            {{ delivery.todoor_interval }}
                        </span>
                        <span class="free" v-if="delivery.todoor_price !== 0 && delivery.todoor_always_paid === 0">
                            бесплатно при заказе от {{ delivery.from_free_delivery }} &#8381
                        </span>
                        <input type="radio" name="delivery_type" value="todoor">
                        <span class="checkmark"></span>
                    </label>
                    <label class="input-radio" @click="changeDeliveryType('pickup')" v-if="delivery.pickup_enable === 1 && count_points > 0">
                        <span class="text">
                            <span>В пункт выдачи — </span>
                            <span v-if="delivery.pickup_price === 0"><span class="green-color">Бесплатно</span></span>
                            <span v-else>{{ delivery.pickup_price }} &#8381;</span>
                        </span>
                        <span class="date">
                            {{ delivery.pickup_interval }}
                        </span>
                        <span class="free" v-if="delivery.pickup_price !== 0">
                            бесплатно при заказе от {{ delivery.from_free_delivery_pickup }} &#8381
                        </span>
                        <input type="radio" name="delivery_type" value="pickup">
                        <span class="checkmark"></span>
                    </label>
                    <label class="input-radio" @click="changeDeliveryType('post')" v-if="delivery.post_enable === 1">
                        <span class="text">
                            <span>Почтой России — </span>
                            <span v-if="delivery.post_price === 0"><span class="green-color">Бесплатно</span></span>
                            <span v-else>{{ delivery.post_price }} &#8381;</span>
                        </span>
                        <span class="date">
                            {{ delivery.post_interval }}
                        </span>
                        <span class="free" v-if="delivery.post_price !== 0">
                            бесплатно при заказе от {{ delivery.from_free_delivery }} &#8381
                        </span>
                        <span class="delay"><i class="fas fa-exclamation-circle"></i> Возможно дополнительное увеличение сроков до 15 дней в связи с высокой загруженностью терминалов Почты России.</span>
                        <input type="radio" name="delivery_type" value="post">
                        <span class="checkmark"></span>
                    </label>
                    <label class="input-radio" @click="changeDeliveryType('express')" v-if="delivery.express_enable === 1 && isWeekend !== true">
                        <span class="text">
                            <span>Экспресс курьером (в пределах МКАД) — </span>
                            <span>649 &#8381;</span>
                        </span>
                        <span class="date">
                            сегодня, при оплате заказа до 16-30
                        </span>
                        <input type="radio" name="delivery_type" value="express">
                        <span class="checkmark"></span>
                    </label>
                    <label class="input-radio" @click="changeDeliveryType('office')" v-if="delivery.office_enable === 1">
                        <span class="text">
                            <span>Самовывоз — </span>
                            <span class="green-color">Бесплатно</span>
                        </span>
                        <span class="date">
                            г. Москва улица Котляковская дом 3 строение 12 (метро Варшавская). <a href="/contacts/#office" target="_blank">Как забрать.</a>
                        </span>
                        <input type="radio" name="delivery_type" value="office">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="block row-todoor-interval" v-if="checkout.delivery.dtype === 'todoor' && delivery.todoor_interval_enable === 1">
                    <h2 :class="{ 'error': $v.checkout.delivery.todoor.interval.$error }">
                        <span v-if="checkout.delivery.todoor.interval">Интервал доставки</span>
                        <span v-else>Выберите интервал доставки</span>
                    </h2>
                    <label class="input-radio">с 9:00 до 18:00
                        <input type="radio" name="todoor_interval" value="с 9:00 до 18:00" v-model="$v.checkout.delivery.todoor.interval.$model">
                        <span class="checkmark"></span>
                    </label>
                    <label class="input-radio">с 9:00 до 13:00
                        <input type="radio" name="todoor_interval" value="с 9:00 до 13:00" v-model="$v.checkout.delivery.todoor.interval.$model">
                        <span class="checkmark"></span>
                    </label>
                    <label class="input-radio">с 12:00 до 15:00
                        <input type="radio" name="todoor_interval" value="с 12:00 до 15:00" v-model="$v.checkout.delivery.todoor.interval.$model">
                        <span class="checkmark"></span>
                    </label>
                    <label class="input-radio">с 15:00 до 18:00
                        <input type="radio" name="todoor_interval" value="с 15:00 до 18:00" v-model="$v.checkout.delivery.todoor.interval.$model">
                        <span class="checkmark"></span>
                    </label>
                    <label class="input-radio">с 18:00 до 22:00
                        <input type="radio" name="todoor_interval" value="с 18:00 до 22:00" v-model="$v.checkout.delivery.todoor.interval.$model">
                        <span class="checkmark"></span>
                    </label>
                    <span class="about-text">
                        <span class="icon"><i class="fas fa-exclamation-circle"></i></span>
                        <span class="text">
                            <p>В связи с транспортной нагрузкой фактическое время прибытия курьера может выйти за рамки выбранного Вами интервала, но Ваше пожелание будет учтено курьерской службой при планировании маршрута.</p>
                            <p>Для доставки в строго определенное время, используйте услугу "Экспресс курьером" или закажите доставку в ПВЗ для самостоятельного получения заказа.</p>
                            <p>Доставка за МКАД осуществляется с 9:00 до 22:00 вне зависимости от выбранного интервала.</p>
                        </span>
                    </span>
                </div>
                <div class="block row-todoor" v-if="checkout.delivery.dtype === 'todoor' || checkout.delivery.dtype === 'express'">
                    <div class="checkout-form">
                        <h2>Адрес доставки</h2>
                        <div class="form-group padding-bottom">
                            <input type="text" class="input-text" :class="{ 'error': $v.checkout.delivery.todoor.street.$error, 'apply': checkout.delivery.todoor.street }" placeholder="Улица" v-model="$v.checkout.delivery.todoor.street.$model">
                        </div>
                        <div class="form-group-inline padding-bottom">
                            <div class="form-group col-4 first">
                                <input type="text" class="input-text" :class="{ 'error': $v.checkout.delivery.todoor.house.$error, 'apply': checkout.delivery.todoor.house }" placeholder="Дом" v-model="$v.checkout.delivery.todoor.house.$model">
                            </div>
                            <div class="form-group col-4">
                                <input type="text" class="input-text" :class="{ 'apply': checkout.delivery.todoor.building }" placeholder="Корпус/строение" v-model="checkout.delivery.todoor.building">
                            </div>
                            <div class="form-group col-4 last">
                                <input type="text" class="input-text" :class="{ 'apply': checkout.delivery.todoor.flat }" placeholder="Квартира/Офис" v-model="checkout.delivery.todoor.flat">
                            </div>
                        </div>
                        <div class="form-group">
                            <textarea :class="{ 'apply': checkout.delivery.todoor.comment }" placeholder="Дополнительный комментарий для доставки" v-model="checkout.delivery.todoor.comment"></textarea>
                        </div>
                    </div>
                </div>
                <div class="block row-pickup-current" v-if="checkout.delivery.pickup.current && checkout.delivery.dtype === 'pickup'">
                    <div class="pickup-current-point">
                        <h2>Выбранный пункт выдачи</h2>
                        <div class="point">
                            <div class="text">
                                <div class="company" v-if="checkout.delivery.pickup.current.company === 'boxberry'">Boxberry</div>
                                <div class="company" v-else-if="checkout.delivery.pickup.current.company === 'cdek'">СДЭК</div>
                                <div class="company" v-else-if="checkout.delivery.pickup.current.company === 'pickpoint'">Pickpoint</div>
                                <div class="address">{{ checkout.delivery.pickup.current.address }}</div>
                                <div class="date_issue">Придет в пункт выдачи {{ delivery.pickup_date_start_short }} - {{ delivery.pickup_date_end_short }}, {{ delivery.pickup_price }} &#8381;</div>
                                <div class="worktime">{{ checkout.delivery.pickup.current.workshedule }}</div>

                            </div>
                            <div class="tripdescription">
                                <div class="title">Как добраться</div>
                                <div class="text">{{ checkout.delivery.pickup.current.tripdescription }}</div>
                            </div>
                            <div class="phone" v-if="checkout.delivery.pickup.current.phone">
                                <div class="title">Контакты</div>
                                <div class="text">{{ checkout.delivery.pickup.current.phone }}</div>
                            </div>
                            <div id="pickup-current-point-map"></div>
                            <PickupPointMap :geo_lat="checkout.delivery.pickup.current.latitude" :geo_lon="checkout.delivery.pickup.current.longitude"></PickupPointMap>
                            <div>
                                <a href="#" class="change_point_btn" @click.prevent="showPickupPointsMap()">Выбрать другой</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block row-pickup" v-if="checkout.delivery.dtype === 'pickup' && checkout.delivery.pickup.current === null">
                    <PickupPointsMap @currentPoint="changeCurrentPoint" :price="delivery.pickup_price" :date_start="delivery.pickup_date_start_short" :date_end="delivery.pickup_date_end_short"></PickupPointsMap>
                </div>
                <div class="block row-post" v-if="checkout.delivery.dtype === 'post'">
                    <div class="checkout-form">
                        <h2>Адрес отправления</h2>
                        <div class="form-group padding-bottom">
                            <input type="text" class="input-text" :class="{ 'error': $v.checkout.delivery.post.code.$error, 'apply': checkout.delivery.post.code }" placeholder="Индекс" v-maska="'######'" v-model.trim="$v.checkout.delivery.post.code.$model">
                        </div>
                        <div class="form-group padding-bottom">
                            <input type="text" class="input-text" :class="{ 'error': $v.checkout.delivery.post.street.$error, 'apply': checkout.delivery.post.street }" placeholder="Улица" v-model="$v.checkout.delivery.post.street.$model">
                        </div>
                        <div class="form-group-inline padding-bottom">
                            <div class="form-group col-4 first">
                                <input type="text" class="input-text" :class="{ 'error': $v.checkout.delivery.post.house.$error, 'apply': checkout.delivery.post.house }" placeholder="Дом" v-model="$v.checkout.delivery.post.house.$model">
                            </div>
                            <div class="form-group col-4">
                                <input type="text" class="input-text" :class="{ 'apply': checkout.delivery.post.building }" placeholder="Корпус/строение" v-model="checkout.delivery.post.building">
                            </div>
                            <div class="form-group col-4 last">
                                <input type="text" class="input-text" :class="{ 'apply': checkout.delivery.post.flat }" placeholder="Квартира" v-model="checkout.delivery.post.flat">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block" v-if="checkout.delivery.dtype != null">
                    <div class="checkout-form">
                        <h2>Получатель</h2>
                        <div class="form-group padding-bottom">
                            <input type="text" class="input-text" :class="{ 'error': $v.checkout.customer.name.$error, 'apply': checkout.customer.name }" placeholder="Фамилия и Имя" v-model.trim="$v.checkout.customer.name.$model">
                            <span class="desc">Полные фамилия, имя и отчество могут потребоваться при получении заказа</span>
                        </div>
                        <div class="form-group-inline">
                            <div class="form-group col-12 col-md-6 first">
                                <input type="text" inputmode="tel" class="input-text" :class="{ 'error': $v.checkout.customer.phone.$error, 'apply': checkout.customer.phone }" id="customer_phone" placeholder="Телефон" v-maska="{mask: '+7(E##)###-##-##', tokens: { 'E': { pattern: /[0-79]/}}}" v-model="$v.checkout.customer.phone.$model" autocomplete="off">
                                <span class="desc">Номер телефона используется для оповещений об изменениях статуса заказа, а также для входа в Ваш Личный кабинет</span>
                            </div>
                            <div class="form-group col-12 col-md-6 last">
                                <input type="text" class="input-text" placeholder="E-mail" :class="{ 'error': $v.checkout.customer.email.$error, 'apply': checkout.customer.email }" v-model.trim="$v.checkout.customer.email.$model">
                                <span class="desc">На данный E-mail придет письмо с деталями заказа, а еще мы будем присылать промокоды на самые выгодные скидки и акции!</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block">
                    <h2 :class="{ 'error': $v.checkout.payment_type.$error }">
                        <span v-if="checkout.payment_type">Способ оплаты</span>
                        <span v-else>Выберите способ оплаты</span>
                    </h2>
                    <label class="input-radio" v-if="checkout.delivery.dtype !== 'office' && checkout.delivery.dtype !== 'express' && checkout.delivery.dtype !== 'post'">Банковской картой при получении
                        <input type="radio" name="payment_type" value="card" v-model="$v.checkout.payment_type.$model">
                        <span class="checkmark"></span>
                        <span class="delay" v-if="checkout.delivery.dtype === 'post'"><i class="fas fa-exclamation-circle"></i> Наложенный платёж оплачивается почтовым переводом в отделении связи. За перевод может взиматься комиссия Почтой. При выборе оплаты "Банковской картой на сайте" комиссия не взимается.</span>
                    </label>
                    <label class="input-radio" v-if="checkout.delivery.dtype !== 'office' && checkout.delivery.dtype !== 'express' && checkout.delivery.dtype !== 'post'">Наличными при получении
                        <input type="radio" name="payment_type" value="cash" v-model="$v.checkout.payment_type.$model">
                        <span class="checkmark"></span>
                        <span class="delay" v-if="checkout.delivery.dtype === 'post'"><i class="fas fa-exclamation-circle"></i> Наложенный платёж оплачивается почтовым переводом в отделении связи. За перевод может взиматься комиссия Почтой. При выборе оплаты "Банковской картой на сайте" комиссия не взимается.</span>
                    </label>
                    <label class="input-radio">Банковской картой на сайте
                        <input type="radio" name="payment_type" value="card_online" v-model="$v.checkout.payment_type.$model">
                        <span class="checkmark"></span>
                        <span class="delay" v-if="checkout.delivery.dtype === 'post'"><i class="fas fa-exclamation-circle"></i> При оплате "Банковской картой на сайте" с Вас не взимается комиссия Почтой России при получении заказа.</span>
                    </label>
                </div>
                <div class="block">
                    <h2>Дополнительно</h2>
                    <div class="form-group">
                        <textarea name="comment" :class="{'apply': checkout.comment}" v-model="checkout.comment" placeholder="Комментарий к заказу"></textarea>
                    </div>
                </div>
                <div class="block d-none d-lg-block">
                    <div class="send-order">
                        <div><a href="#" class="btn-oder-send" @click.prevent="addOrder()">Отправить заказ</a></div>
                        <div class="errors" v-if="errors">
                            <div v-if="!$v.checkout.delivery.dtype.required">
                                Выберите способ доставки.
                            </div>
                            <div v-if="checkout.delivery.dtype === 'todoor' && delivery.todoor_interval_enable === 1">
                                <div v-if="!$v.checkout.delivery.todoor.interval.required">Выберите интервал доставки.</div>
                            </div>
                            <div v-if="!$v.checkout.payment_type.required">
                                Выберите способ оплаты.
                            </div>
                            <div v-if="checkout.delivery.dtype === 'pickup' && !$v.checkout.delivery.pickup.current.id">
                                Выберите подходящий пунт выдачи заказа.
                            </div>
                            <div v-if="$v.$invalid && !$v.checkout.delivery.dtype.$error">
                                <div>Заполните контактные данные получателя и все необходимые поля для доставки.</div>
                            </div>
                        </div>
                        <div class="privacy">
                            Нажимая кнопку «Отправить заказ», Вы даете свое согласие на обработку персональных данных, на условиях и для целей, определенных <a href="https://docs.google.com/document/d/1YypIAVQbzKMdlYKfG2UUyquQcwjcNgi1K7Y7OzN9r2k/edit?usp=sharing" target="_blank">Политикой конфиденциальности</a>.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-5 col-xl-4">
                <div class="total">
                    <div class="total-sum">
                        <div class="row no-gutters total-sum__products">
                            <div class="col-6"><div class="text">Товаров</div></div>
                            <div class="col-6"><div class="num red">{{ totalPriceWithoutSales }} &#8381;</div></div>
                        </div>
                        <div class="row no-gutters total-sum__promocode" v-if="this.$store.state.Shop.cartPromocode.name">
                            <div class="col-6"><div class="text">Промокод</div></div>
                            <div class="col-6"><div class="num">{{ $store.state.Shop.cartPromocode.name }}</div></div>
                        </div>
                        <div class="row no-gutters total-sum__sale" v-if="totalSaleInCart > 0">
                            <div class="col-6"><div class="text">Скидка на товары</div></div>
                            <div class="col-6"><div class="num red">-{{ totalSaleInCart }} &#8381;</div></div>
                        </div>
                        <div class="row no-gutters total-sum__delivery" v-if="checkout.delivery.dtype !== null">
                            <div class="col-6">
                                <div class="text" v-if="checkout.delivery.dtype === 'office'">Самовывоз</div>
                                <div class="text" v-else>Доставка</div>
                            </div>
                            <div class="col-6" v-if="checkout.delivery.total === 0"><div class="num green">Бесплатно</div></div>
                            <div class="col-6" v-else><div class="num">{{ checkout.delivery.total }} &#8381;</div></div>
                        </div>
                        <div class="row no-gutters total-sum__total">
                            <div class="col-6"><div class="text">Итого</div></div>
                            <div class="col-6"><div class="num">{{ totalPriceInCart }} &#8381;</div></div>
                        </div>
                    </div>
                    <div class="send-order">
                        <div><a href="#" class="btn-oder-send" @click.prevent="addOrder()">Отправить заказ</a></div>
                        <div class="errors" v-if="errors">
                            <div v-if="!$v.checkout.delivery.dtype.required">
                                Выберите способ доставки.
                            </div>
                            <div v-if="checkout.delivery.dtype === 'todoor' && delivery.todoor_interval_enable === 1">
                                <div v-if="!$v.checkout.delivery.todoor.interval.required">Выберите интервал доставки.</div>
                            </div>
                            <div v-if="!$v.checkout.payment_type.required">
                                Выберите способ оплаты.
                            </div>
                            <div v-if="$v.$invalid && !$v.checkout.delivery.dtype.$error">
                                <div v-if="checkout.delivery.dtype === 'pickup' && !$v.checkout.delivery.pickup.current.id.required">
                                    Выберите подходящий пунт выдачи заказа.
                                </div>
                                <div>Заполните контактные данные получателя и все необходимые поля для доставки.</div>
                            </div>
                        </div>
                        <div class="privacy">
                            Нажимая кнопку «Отправить заказ», Вы даете свое согласие на обработку персональных данных, на условиях и для целей, определенных <a href="https://docs.google.com/document/d/1YypIAVQbzKMdlYKfG2UUyquQcwjcNgi1K7Y7OzN9r2k/edit?usp=sharing" target="_blank">Политикой конфиденциальности</a>.
                        </div>
                    </div>
                    <div class="order-list d-none d-lg-block">
                        <h2>Ваш заказ</h2>
                        <ul class="list">
                            <li class="row no-gutters" v-for="item in $store.state.Shop.cart" :key="item.variant_id">
                                <div class="col-2">
                                    <div class="image">
                                        <img :src="'/storage/' + item.image_url" alt="" v-if="item.image_url">
                                        <img src="/images/frontend/no-image.png" alt="" v-else>
                                    </div>
                                </div>
                                <div class="col-10">
                                    <div class="content">
                                        <div class="name">{{ item.name }}</div>
                                        <div class="quantity">{{ item.in_cart_quantity }} x {{ item.unit_name }}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="loading" v-if="loading === true"></div>
        <div class="modal-overlay" v-if="modal"></div>
        <div class="modal no-promocode" v-if="modal">
            <div class="modal-header">
                <div class="title">Извините!</div>
            </div>
            <div class="modal-body">
                <div>
                    <span>К сожалению, Вы не можете использовать введенный промокод.</span>
                    <span>Сумма заказа пересчитается автоматически и Вы сможете заново оформить заказ.</span>
                </div>
                <a href="#" class="btn green-bordered-button" @click.prevent="closeModal()">Закрыть</a>
            </div>
        </div>
    </div>
</template>
<script>
    import { maska } from 'maska'

    import { required, minLength, email } from 'vuelidate/lib/validators'

    import PickupPointsMap from './PickupPointsMap.vue';
    import PickupPointMap from './PickupPointMap.vue';

    export default {
        data() {
            return {
                loading: false,
                componentKey: 0,
                //Параметры доставки и цена доставки
                delivery: {},
                count_points: 0,
                errors: null,
                modal: false,
                checkout: {
                    location: this.$store.state.Shop.location,
                    payment_type: null,
                    delivery: {
                        dtype: null,
                        todoor: {
                            street: null,
                            house: null,
                            building: null,
                            flat: null,
                            comment: null,
                            todoor_tk_name: null,
                            interval: null
                        },
                        pickup: {
                            current: null,
                        },
                        post: {
                            street: null,
                            code: null,
                            house: null,
                            building: null,
                            flat: null
                        },
                        total: 0
                    },
                    customer: {
                        name: null,
                        phone: null,
                        email: null
                    },
                    promocode_id: null,
                    products_summ: 0,
                    products_sales: 0,
                    products_total: 0,
                    total_summ: 0,
                    products: this.$store.state.Shop.cart,
                    comment: null,
                    order_status_id: 1
                },
                isWeekend: false,
            };
        },
        validations() {
            if (this.checkout.delivery.dtype === 'todoor') {
                if (this.delivery.todoor_interval_enable === 1) {
                    return {
                        checkout: {
                            delivery: {
                                dtype: {
                                    required,
                                },
                                todoor: {
                                    interval: {
                                        required,
                                    },
                                    street: {
                                        required,
                                        minLength: minLength(1)
                                    },
                                    house: {
                                        required,
                                        minLength: minLength(1)
                                    },
                                }
                            },
                            payment_type: {
                                required,
                            },
                            customer: {
                                name: {
                                    required,
                                    minLength: minLength(2)
                                },
                                phone: {
                                    required,
                                    minLength: minLength(16)
                                },
                                email: {
                                    email,
                                }
                            }
                        }
                    }
                } else {
                    return {
                        checkout: {
                            delivery: {
                                dtype: {
                                    required,
                                },
                                todoor: {
                                    street: {
                                        required,
                                        minLength: minLength(1)
                                    },
                                    house: {
                                        required,
                                        minLength: minLength(1)
                                    },
                                }
                            },
                            payment_type: {
                                required,
                            },
                            customer: {
                                name: {
                                    required,
                                    minLength: minLength(2)
                                },
                                phone: {
                                    required,
                                    minLength: minLength(16)
                                },
                                email: {
                                    email,
                                }
                            }
                        }
                    }
                }
            } else if (this.checkout.delivery.dtype === 'express') {
                return {
                    checkout: {
                        delivery: {
                            dtype: {
                                required,
                            },
                            todoor: {
                                street: {
                                    required,
                                    minLength: minLength(1)
                                },
                                house: {
                                    required,
                                    minLength: minLength(1)
                                },
                            }
                        },
                        payment_type: {
                            required,
                        },
                        customer: {
                            name: {
                                required,
                                minLength: minLength(2)
                            },
                            phone: {
                                required,
                                minLength: minLength(16)
                            },
                            email: {
                                email,
                            }
                        }
                    }
                }
            } else if (this.checkout.delivery.dtype === 'pickup') {
                return {
                    checkout: {
                        delivery: {
                            dtype: {
                                required,
                            },
                            pickup: {
                                current: {
                                    id: required
                                },
                            }
                        },
                        payment_type: {
                            required,
                        },
                        customer: {
                            name: {
                                required,
                                minLength: minLength(2)
                            },
                            phone: {
                                required,
                                minLength: minLength(16)
                            },
                            email: {
                                email,
                            }
                        }
                    }
                }
            } else if (this.checkout.delivery.dtype === 'post') {
                return {
                    checkout: {
                        delivery: {
                            dtype: {
                                required,
                            },
                            post: {
                                street: {
                                    required,
                                    minLength: minLength(1)
                                },
                                code: {
                                    required,
                                    minLength: minLength(6)
                                },
                                house: {
                                    required,
                                    minLength: minLength(1)
                                }
                            }
                        },
                        payment_type: {
                            required,
                        },
                        customer: {
                            name: {
                                required,
                                minLength: minLength(2)
                            },
                            phone: {
                                required,
                                minLength: minLength(16)
                            },
                            email: {
                                email,
                            }
                        }
                    }
                }
            } else {
                return {
                    checkout: {
                        delivery: {
                            dtype: {
                                required,
                            },
                        },
                        payment_type: {
                            required,
                        },
                        customer: {
                            name: {
                                required,
                                minLength: minLength(2)
                            },
                            phone: {
                                required,
                                minLength: minLength(16)
                            },
                            email: {
                                email,
                            }
                        }
                    }
                }
            }
        },
        components: {
            PickupPointsMap,
            PickupPointMap
        },
        mounted() {
            if (this.$store.state.Shop.cart.length === 0) {
                window.location.href = '/cart/';
            }
            this.$store.dispatch('Shop/updateCart');
            this.getCustomer();
            this.getDeliveryPrice();
            this.checkout.promocode_id = this.$store.state.Shop.cartPromocode.id;
            this.checkWeekend();
        },
        methods: {
            forceRerender() {
                this.componentKey += 1;
            },
            changeCurrentPoint(point) {
                this.checkout.delivery.pickup.current = point;
            },
            changeDeliveryType(type) {
                this.checkout.delivery.dtype = type;

                if(type === 'pickup') {
                    this.forceRerender();
                    this.checkout.delivery.total = this.delivery.pickup_price;
                } else if (type === 'todoor') {
                    this.checkout.delivery.total = this.delivery.todoor_price;
                    //Подставляем название ТК курьера
                    this.checkout.delivery.todoor.todoor_tk_name = this.delivery.todoor_tk_name;
                } else if (type === 'post') {
                    this.checkout.delivery.total = this.delivery.post_price;
                } else if (type === 'office') {
                    this.checkout.delivery.total = 0;
                } else if (type === 'express') {
                    this.checkout.delivery.total = 649;
                }

                if (this.totalPriceWithSales >= this.delivery.from_free_delivery && type !== 'express') {
                    //Проверяем бесплатную доставку курьером для региона
                    if (type === 'todoor' && this.delivery.todoor_always_paid === 1) {
                        this.checkout.delivery.total = this.delivery.todoor_price;
                    } else {
                        this.checkout.delivery.total = 0;
                    }
                }
            },
            showPickupPointsMap() {
                this.checkout.delivery.pickup.current = null;
            },
            async getDeliveryPrice() {
                let kladr_id = this.$store.state.Shop.location.data.region_kladr_id;
                let total = 0;

                let boxberry_id = this.$store.state.Shop.location.delivery.boxberry_id,
                    cdek_id = this.$store.state.Shop.location.delivery.cdek_id,
                    city_name;

                if (this.$store.state.Shop.location.data.settlement != null) {
                    city_name = this.$store.state.Shop.location.data.settlement;
                } else {
                    city_name = this.$store.state.Shop.location.data.city;
                }

                this.$store.dispatch('Shop/showGlobalLoading');

                await axios.get('/api/frontend/delivery/get', {
                        params: {
                            'kladr_id': kladr_id,
                        }
                    })
                    .then((response) => {
                        this.delivery = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });

                //Считаем количество пунктов выдачи
                await axios.get('/api/helpers/pickup/points/get', {
                    params: {
                        'boxberry_id': boxberry_id,
                        'cdek_id': cdek_id,
                        'city_name': city_name,
                        'count': 1
                    }
                })
                .then((response) => {
                    this.count_points = response.data;
                })
                .catch(error => {
                    console.log(error);
                });

                //Проверяем бесплатную доставку
                for (let item of this.$store.state.Shop.cart) {
                    total += item.in_cart_quantity * item.in_cart_total_price;
                }
                if (this.totalPriceWithSales >= this.delivery.from_free_delivery) {
                    //Проверяем бесплатную доставку курьером для региона
                    if(this.delivery.todoor_always_paid === 0) {
                        this.delivery.todoor_price = 0;
                    }
                    this.delivery.post_price = 0;
                }

                if (this.totalPriceWithSales >= this.delivery.from_free_delivery_pickup) {
                    this.delivery.pickup_price = 0;
                }

                this.$store.dispatch('Shop/hideGlobalLoading');
            },
            async getCustomerDeliveryInfo() {

            },
            async addOrder() {
                this.$store.dispatch('Shop/showGlobalLoading');

                let vm = this;
                let order_id;
                let current_dtype = this.checkout.delivery.dtype;
                let total_sum = this.checkout.total_summ;
                let payment_type = this.checkout.payment_type;
                let error_promocode_for_new_customer = false;

                this.$v.$touch();

                if (this.$v.$invalid) {

                    this.errors = 'ERROR';
                    this.$store.dispatch('Shop/hideGlobalLoading');

                } else {

                    this.errors = null;

                    if (this.checkout.promocode_id && this.$store.state.Shop.cartPromocode.for_new_customer === 1) {
                        await axios.post('/api/frontend/promocode/check_for_new_customer', {
                            'promocode_id': vm.checkout.promocode_id,
                            'customer_phone': vm.checkout.customer.phone
                        }).then((response) => {
                            if (response.data.error || response.data.errors) {
                                vm.error_promocode_for_new_customer = true;
                            }
                        }).catch(error => {
                            console.log(error);
                        });
                        if (this.error_promocode_for_new_customer === true) {
                            this.$store.dispatch('Shop/deletePromocode');
                            this.$store.dispatch('Shop/updateCart');
                            this.modal = true;
                            return false;
                        }
                    }

                    await axios.post('/api/frontend/order/add', this.checkout)
                        .then((response) => {
                            vm.$store.dispatch('Shop/cleanCart');
                            order_id = response.data.order_id;
                        })
                        .catch(error => {
                            console.log(error);
                        });

                    localStorage.setItem('customer_not_new', 1);

                    if (payment_type === 'card_online') {
                        window.location.href = '/success/' + order_id + '/' + total_sum;
                    } else {
                        window.location.href = '/success/' + order_id;
                    }
                }
            },
            getCustomer() {
                let customer = this.$store.state.Shop.customer;
                if (customer.phone && customer.token) {
                    axios.post('/api/customer/get', {
                        "phone": customer.phone,
                        "token": customer.token
                    })
                    .then((response) => {
                        this.checkout.customer.name = response.data.name;
                        this.checkout.customer.phone = response.data.phone;
                        this.checkout.customer.email = response.data.email;
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
            },
            checkWeekend() {
                let date = new Date();
                let day = date.getDay();

                if (day === 6 || day === 0) {
                    this.isWeekend = true;
                }
            },
            closeModal() {
                this.modal = false;
                window.location.reload();
            }
        },
        computed: {
            totalPriceInCart() {
                let total = 0;

                for (let item of this.$store.state.Shop.cart) {
                    total += item.in_cart_total_price;
                }

                total += this.checkout.delivery.total;

                this.checkout.total_summ = total;

                return total;
            },
            totalSaleInCart() {
                let total = 0;
                for (let item of this.$store.state.Shop.cart) {
                    total += item.price * item.in_cart_quantity - item.in_cart_total_price;
                }

                this.checkout.products_sales = total;

                return total;
            },
            totalPriceWithoutSales() {
                let total = 0;

                for (let item of this.$store.state.Shop.cart) {
                    total += item.price * item.in_cart_quantity;
                }

                this.checkout.products_summ = total;

                return total;
            },
            totalPriceWithSales() {
                let total = 0;

                for (let item of this.$store.state.Shop.cart) {
                    total += item.in_cart_total_price;
                }

                this.checkout.products_total = total;

                return total;
            }
        },
    }
</script>
<style>
    .modal.no-promocode .modal-body span {
        display: block;
        line-height: 1.3;
        margin-bottom: .5rem;
    }
    .modal.no-promocode a {
        display: block;
        width: 100%;
        box-sizing: border-box;
        height: 60px;
        line-height: 60px;
        padding: 0;
        font-size: 1.25rem;
    }
</style>
