<template>
    <div class="header-button cart">
        <div class="icon" onclick="window.location='/cart/'">
            <svg>
                <use xlink:href="/images/frontend/icons.svg#cart" />
            </svg>
        </div>
        <div class="count" onclick="window.location='/cart/'" v-if="$store.state.Shop.cartCount">
            <span>{{ $store.state.Shop.cartCount }}</span>
        </div>
        <div class="text" onclick="window.location='/cart/'">Корзина</div>
        <div v-if="$store.state.Shop.cart.length > 0" class="dropdown">
            <div class="inner">
                <ul>
                    <li v-for="item in $store.state.Shop.cart" :key="item.variant_id">
                        <a href="#" class="close" @click.prevent="removeFromCart(item)">
                            <img src="/images/frontend/close.svg">
                        </a>
                        <span class="image"></span>
                        <span class="text">
                            <a href="#" class="name">{{ item.name }}</a>
                            <span class="quantity">{{ item.in_cart_quantity }} x {{ item.unit_name }}</span>
                            <span class="summ">{{ item.in_cart_total_price }} &#8381;</span>
                        </span>
                        <div style="clear: both"></div>
                    </li>
                </ul>
                <div class="total">
                    <div class="sum">
                        <span class="text">Сумма</span>
                        <span class="num">{{ totalPrice }} &#8381;</span>
                    </div>
                    <div class="order">
                        <a href="/cart/">Оформить заказ</a>
                    </div>
                    <div style="clear: both;"></div>
                </div>
            </div>
        </div>
        <div v-else class="dropdown">
            <div class="inner">
                Корзина пустая
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            removeFromCart(product) {
                this.$store.dispatch('Shop/removeFromCart', product);
            }
        },
        computed: {
            totalPrice() {
                let total = 0;

                for (let item of this.$store.state.Shop.cart) {
                    total += item.in_cart_total_price;
                }

                return total;
            }
        }
    };
</script>

<style>
    .header-button.cart .dropdown {
        display: none;
        position: absolute;
        top: 25px;
        right: -10px;
        z-index: 200;
        width: 500px;
        text-align: left;
        padding-top: 12px;
    }
    .header-button.cart .dropdown .inner {
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.12), 0 9px 46px 0 rgba(0,0,0,.06);
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background: #fff;
        padding: 30px 30px;
        position: relative;
    }
    .header-button.cart .dropdown .inner:after {
        content: '';
        display: block;
        position: absolute;
        right: 10px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-bottom: 10px solid #fff;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
    /*.header-button.cart:hover .dropdown {
        display: block;
    }*/
    .header-button.cart ul,
    .header-button.cart li {
        display: block;
        margin: 0;
        padding: 0;
        position: relative;
    }
    .header-button.cart li {
        overflow: hidden;
        padding: 20px 0;
        border-bottom: 1px solid #eee;
    }
    .header-button.cart li span,
    .header-button.cart li a {
        display: block;
    }
    .header-button.cart li .image {
        height: 100px;
        width: 100px;
        background: #EEEEEE;
        float: left;
    }
    .header-button.cart li .image img {
        width: 100%;
        height: auto;
    }
    .header-button.cart li .text {
        margin-left: 120px;
        padding-bottom: 20px;
    }
    .header-button.cart li .text .name {
        padding-bottom: 10px;
    }
    .header-button.cart li .text .quantity {
        font-size: 0.875rem;
        font-size: 14px;
        padding-bottom: 10px;
        color: #9C531D;
    }
    .header-button.cart li .text .summ {
        font-size: 1.125rem;
        font-size: 18px;
    }
    .header-button.cart li .close {
        position: absolute;
        top: 20px;
        right: 0px;
    }
    .header-button.cart li .close img {
        height: 15px;
        width: 15px;
    }
    .header-button.cart .total {
        padding-top: 20px;
    }
    .header-button.cart .total span {
        display: block;
        overflow: hidden;
    }
    .header-button.cart .total .sum {
        width: 100px;
        float: left;
    }
    .header-button.cart .total .sum .text {
        color: #777777;
    }
    .header-button.cart .total .sum .num {
        font-size: 1.375rem;
        font-size: 22px;
        padding-top: 7px;
    }
    .header-button.cart .total .order {
        margin-left: 120px;
    }
    .header-button.cart .total .order a {
        width: 200px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        border: 1px solid #1D9C56;
        text-align: center;
        padding: 1rem 1rem;
        text-decoration: none;
        color: #1D9C56;
        display: block;
        background: #1D9C56;
        color: #fff;
    }
    .header-button.cart .total .order a:hover,
    .header-button.cart .total .order a:active {
        background: #188047;
        border: 1px solid #188047;
    }
</style>