const target = document.body;

const config = {
    childList: true
}

const callback = function (mutationList, observer) {
    for (let mutation of mutationList) {
        if (mutation.addedNodes.length > 0 && mutation.addedNodes[0].tagName === 'DIV') {
            const div = mutation.addedNodes[0];
            const buttons = div.querySelectorAll('div > div > div > div > a[title]');
            if (buttons.length !== 0) {
                const closeButtonTitle = buttons[2].getAttribute('title').replace('&zwnj;', '');
                const found = closeButtonTitle.match(/^.?ы.*ь.?$/);
                if (found) {
                    div.remove();
                }
            }
        }
    }
}

const observer = new MutationObserver(callback);

observer.observe(target, config);
