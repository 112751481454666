<template>
    <div id="add-product-review" :class="{nomarginbottom: count_reviews === 0}">
        <div class="button" v-if="!show_form">
            <a href="#" class="btn" @click.prevent="showForm()">Оставить отзыв</a>
        </div>
        <div class="form" v-if="show_form && !success">
            <div class="form-group">
                <input type="text" class="input-text" name="review_person" id="review_person" placeholder="Представьтесь, пожалуйста" v-model="person">
            </div>
            <div class="form-group">
                <input type="text" class="input-text" name="review_phone" id="review_phone" v-maska="'+7(###)###-##-##'" placeholder="Контактный телефон (не публикуется)" v-model="phone" autocomplete="off">
            </div>
            <div class="form-group">
                <input type="text" class="input-text" name="review_title" id="review_title" placeholder="В 2-3х словах Ваше впечатление" v-model="title">
            </div>
            <div class="form-group">
                <textarea name="review_text" id="review_text" placeholder="Отзыв о товаре" v-model="text"></textarea>
            </div>
            <div class="form-group">
                <div class="review-images-block">
                    <div class="review-images-list">
                        <div class="item" v-for="(image, index) in photos_base64">
                            <img :src="image">
                            <a href="#" @click.prevent="deleteImage(index)">Удалить</a>
                        </div>
                    </div>
                    <input type="file" id="imageCropFileInput" accept=".jpg,.jpeg,.png" multiple="" @change="showCropper">
                    <a href="#" class="add-button" @click.prevent="selectFile" v-show="add_image_button_show && count_images < 5">
                        <div class="add-button-title">Добавить изображение для отзыва</div>
                        <div class="add-button-icon"><i class="fas fa-file-image"></i></div>
                        <div class="add-button-text">В формате jpeg, jpg, png</div>
                    </a>
                    <div class="review-images-cropper" v-show="cropper_block_show">
                        <div class="review-images-cropper-buttons">
                            <a href="#" class="apply" @click.prevent="applyCrop">Сохранить</a>
                            <a href="#" class="cancel" @click.prevent="cancelCrop">Отменить</a>
                        </div>
                        <img src="" id="upload-image">
                    </div>
                </div>
            </div>
            <div class="form-group submit">
                <div class="row">
                    <div class="col-12">
                        <a href="#" class="btn-form-send" @click.prevent="submitForm()">Отправить отзыв</a>
                    </div>
                    <div class="col-12">
                        <a href="#" class="btn-form-close" @click.prevent="showForm()">Закрыть</a>
                    </div>
                </div>
            </div>
            <div class="errors" v-if="errors">
                Не все поля заполнены полностью.
            </div>
        </div>
        <div class="success" v-if="success">Спасибо за Ваш отзыв!<br/>После модерации он обязательно появится на сайте.</div>
    </div>
</template>
<script>
    import Cropper from 'cropperjs';
    import { maska } from 'maska'
    import { required, minLength } from 'vuelidate/lib/validators'

    export default {
        data() {
            return {
                person: null,
                phone: null,
                title: null,
                text: null,
                show_form: false,
                errors: null,
                success: false,
                cropper: null,
                add_image_button_show: true,
                cropper_block_show: false,
                count_images: 0,
                photos_base64: [],
            }
        },
        props: {
            product_id: Number,
            product_name: String,
            count_reviews: Number
        },
        validations: {
            person: {
                required
            },
            phone: {
                required
            },
            title: {
                required
            },
            text: {
                required
            }
        },
        methods: {
            showForm() {
                this.show_form = !this.show_form;
                this.cancelCrop();
            },
            submitForm() {
                this.$store.dispatch('Shop/showGlobalLoading');
                let vm = this;
                this.$v.$touch();

                if (this.$v.$invalid) {
                    this.errors = 'ERROR';
                    this.$store.dispatch('Shop/hideGlobalLoading');
                } else {
                    this.errors = null;

                    axios.post('/api/frontend/product-review/add', {
                        product_id: this.product_id,
                        product_name: this.product_name,
                        person: this.person,
                        phone: this.phone,
                        title: this.title,
                        text: this.text,
                        photos_base64: this.photos_base64
                    })
                    .then((response) => {
                        this.$store.dispatch('Shop/hideGlobalLoading');
                        this.success = true;
                    }, (error) => {
                        this.$store.dispatch('Shop/hideGlobalLoading');
                        console.log(error);
                    });
                }
            },
            selectFile() {
                document.getElementById('imageCropFileInput').click();
            },
            showCropper(e) {
                let image = document.getElementById('upload-image');
                let files = e.target.files;
                let file;
                let reader;
                let done = function (url) {
                    image.src = url;
                };

                if (files && files.length > 0) {
                    file = files[0];
                    if (URL) {
                        done(URL.createObjectURL(file));
                    } else if (FileReader) {
                        reader = new FileReader();
                        reader.onload = function (e) {
                            done(reader.result);
                        };
                        reader.readAsDataURL(file);
                    }
                }

                this.add_image_button_show = false;
                this.cropper_block_show = true;

                this.cropper = new Cropper(image, {
                    aspectRatio: 4 / 3,
                    viewMode: 3,
                });
            },
            cancelCrop() {
                if (this.cropper !== null) {
                    this.cropper.destroy();
                    this.cropper = null;
                    this.add_image_button_show = true;
                    this.cropper_block_show = false;
                }
            },
            applyCrop() {
                let canvas = this.cropper.getCroppedCanvas({
                    width: 800,
                    height: 600,
                });

                let vm = this;

                vm.count_images += 1;

                canvas.toBlob(function(blob) {
                    let url = URL.createObjectURL(blob);
                    let reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function() {
                        let base64data = reader.result;
                        vm.photos_base64.push(base64data);
                        vm.cancelCrop();
                    }
                });
            },
            deleteImage(i) {
                this.$delete(this.photos_base64, i);
            }
        }
    }
</script>
