<template>
    <div class="delivery-block" v-if="region_kladr_id > 0">
        <div class="delivery-block__title">Варианты доставки:</div>
        <div class="region">Ваш регион: <LocationButton></LocationButton></div>
        <div class="item" v-if="todoor_price > 0 && todoor_enable === 1">
            <span>Курьером — {{ todoor_price }} &#8381;</span>
        </div>
        <div class="item" v-if="pickup_price > 0 && pickup_enable === 1">
            <span>В пункт выдачи — {{ pickup_price }} &#8381;</span>
            <div class="grey_desc">(Boxberry, СДЭК на выбор)</div>
        </div>
        <div class="item" v-if="post_price > 0 && post_enable === 1">
            <span>Почтой России — {{ post_price }} &#8381;</span>
        </div>
<!--        <div class="item" v-if="from_free_delivery > 0" style="color: #1D9C56;">Бесплатно при заказе от {{ from_free_delivery }} &#8381;</div>-->
        <div class="item" v-if="from_free_delivery_pickup > 0 && from_free_delivery !== from_free_delivery_pickup" style="color: #1D9C56;">Бесплатно в ПВЗ при заказе от {{ from_free_delivery_pickup }} &#8381;</div>
        <div class="more"><a href="/deliveries-and-payments/">Подробнее про условия доставки</a></div>
    </div>
</template>
<script>
    import LocationButton from './../location/LocationButton.vue'
    export default {
        data() {
            return {
                region_kladr_id: 0,
                todoor_price: 0,
                pickup_price: 0,
                post_price: 0,
                from_free_delivery: 0,
                from_free_delivery_pickup: 0,
                todoor_enable: 0,
                pickup_enable: 0,
                post_enable: 0
            };
        },
        components: {
            LocationButton
        },
        mounted() {
            this.region_kladr_id = this.$store.state.Shop.location.data.region_kladr_id;
            if (this.region_kladr_id > 0) {
                this.getDelivery();
            }
        },
        methods: {
            getDelivery() {
                axios.get('/api/frontend/delivery/get', {
                    params: {
                        'kladr_id': this.region_kladr_id,
                    }
                })
                    .then((response) => {
                        this.pickup_price = response.data.pickup_price;
                        this.todoor_price = response.data.todoor_price;
                        this.post_price = response.data.post_price;
                        this.todoor_enable = response.data.todoor_enable;
                        this.pickup_enable = response.data.pickup_enable;
                        this.post_enable = response.data.post_enable;
                        this.from_free_delivery = response.data.from_free_delivery;
                        this.from_free_delivery_pickup = response.data.from_free_delivery_pickup;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }
</script>
