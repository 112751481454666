<template>
    <div class="list">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 col-xl-4" v-if="todoor_price > 0 && todoor_enable === 1">
                <div class="item">
                    <div class="text">Курьером</div>
                    <div class="desc">Курьер привезет Ваш заказ домой или в офис</div>
                    <div class="price">{{ todoor_price }} &#8381;</div>
                    <div class="free" v-if="from_free_delivery > 0 && todoor_always_paid === 0">Бесплатно при заказе от {{ from_free_delivery }} &#8381;</div>
                    <div class="time" v-if="todoor_interval">{{ todoor_interval }}</div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-4" v-if="pickup_price > 0 && pickup_enable === 1">
                <div class="item">
                    <div class="text">В пункт выдачи</div>
                    <div class="desc">Boxberry, СДЭК на выбор по единой цене</div>
                    <div class="price">{{ pickup_price }} &#8381;</div>
                    <div class="free" v-if="from_free_delivery_pickup > 0">Бесплатно при заказе от {{ from_free_delivery_pickup }} &#8381;</div>
                    <div class="time" v-if="pickup_interval">{{ pickup_interval }}</div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-4" v-if="post_price > 0 && post_enable === 1">
                <div class="item">
                    <div class="text">Почта России</div>
                    <div class="desc">Получите Ваш заказа в удобном отделении почты</div>
                    <div class="price">{{ post_price }} &#8381;</div>
                    <div class="free" v-if="from_free_delivery > 0">Бесплатно при заказе от {{ from_free_delivery }} &#8381;</div>
                    <div class="time" v-if="post_interval">{{ post_interval }}</div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-4" v-if="express_enable === 1">
                <div class="item">
                    <div class="text">Экспресс курьером</div>
                    <div class="desc">Доставим Ваш заказ в этот же или на следующий день</div>
                    <div class="price">649 &#8381;</div>
                    <div class="free" v-if="from_free_delivery > 0">В пределах МКАД</div>
                    <div class="time">Сегодня, при оплате заказа до 16-30</div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-4" v-if="office_enable === 1">
                <div class="item">
                    <div class="text">Самовывоз</div>
                    <div class="desc">Заберите Ваш заказ с нашего офиса выдачи заказов</div>
                    <div class="price">0 &#8381;</div>
                    <div class="free">Бесплатно</div>
                    <div class="time">Только для оплаченных заказов</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                region_kladr_id: 0,
                todoor_price: 0,
                pickup_price: 0,
                post_price: 0,
                from_free_delivery: 0,
                from_free_delivery_pickup: 0,
                todoor_enable: 0,
                pickup_enable: 0,
                post_enable: 0,
                office_enable: 0,
                express_enable: 0,
                todoor_interval: null,
                pickup_interval: null,
                post_interval: null,
                todoor_always_paid: 0
            };
        },
        mounted() {
            this.region_kladr_id = this.$store.state.Shop.location.data.region_kladr_id;
            if (this.region_kladr_id > 0) {
                this.getDelivery();
            }
        },
        methods: {
            getDelivery() {
                axios.get('/api/frontend/delivery/get', {
                    params: {
                        'kladr_id': this.region_kladr_id,
                    }
                })
                    .then((response) => {
                        this.pickup_price = response.data.pickup_price;
                        this.todoor_price = response.data.todoor_price;
                        this.post_price = response.data.post_price;
                        this.todoor_enable = response.data.todoor_enable;
                        this.pickup_enable = response.data.pickup_enable;
                        this.post_enable = response.data.post_enable;
                        this.office_enable = response.data.office_enable;
                        this.express_enable = response.data.express_enable;
                        this.from_free_delivery = response.data.from_free_delivery;
                        this.from_free_delivery_pickup = response.data.from_free_delivery_pickup;
                        this.todoor_interval = response.data.todoor_interval;
                        this.pickup_interval = response.data.pickup_interval;
                        this.post_interval = response.data.post_interval;
                        this.todoor_always_paid = response.data.todoor_always_paid;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }
</script>
