<template>
    <div v-if="position === 'category'">
        <div class="product-vertical__variants" v-if="variants.length > 1">
            <div class="product-vertical__preorder-text" v-if="variant.product.active === 2 || variant.product.active === 3">Последняя цена</div>
            <div class="variants-block">
                <transition name="fade">
                    <div class="description" v-if="show_description">
                        <div v-html="variant_description"></div>
                        <a href="#" class="close" @click.prevent="closeDescription()"></a>
                    </div>
                </transition>
                <div class="variants">
                    <div :offset="variant_menu_offset" class="check" :width="variant_menu_item_with" :style="{'width': 'calc(' + variant_menu_item_with + '%)', 'transform': 'translateX(calc(' + variant_menu_item_with_calc + '%)'}"></div>
                    <div class="item" v-for="(variant_menu, index) in variants" :key="variant_menu.id"  @click.prevent="changeVariant(index)">
                        <span v-if = "variants.length > 2">
                            {{ variant_menu.unit.short_name }}
                        </span>
                        <span v-else>
                            {{ variant_menu.unit.name }}
                        </span>
                        <i class="far fa-question-circle" v-if="variant_menu.unit.short_description" @click.prevent="openDescription(index)"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="product-vertical__pack" v-if="variants.length === 1 && variant.unit.show_one !== 0">{{ variant.unit.name }}</div>
        <div class="product-vertical__bottom" :class="{'preorder': variant.product.active === 2 || variant.product.active === 3}">
            <div class="product-vertical__price">
                <div class="price">
                    <div class="price__old" v-if="variant.product.discount > 0">{{ variant.price }}&#8381;</div>
                    <div class="price__now" :class="{'red': variant.product.discount > 0}">{{ variant.total_price }}</div>
                    <div class="price_currency" :class="{'red': variant.product.discount > 0}">&#8381;</div>
                </div>
            </div>
            <div class="product-vertical__buy" v-if="variant.product.active === 1">
                <buy-button :product_id="variant.product_id" :variant_id="variant.id" :quantity="quantity" :name="variant.product.name" :unit_name="variant.unit.short_name" :price="variant.price" :total_price="variant.total_price" :discount="variant.product.discount" :discount_type="variant.product.discount_type" :image_url="variant.product.mainimage" :slug="variant.product.slug"></buy-button>
            </div>
        </div>
        <div class="product-vertical__preorder" v-if="variant.product.active === 2 || variant.product.active === 3">
            <a href="#" class="green-bordered-button" @click.prevent="showModal()"><i class="far fa-bell"></i> Сообщить о поступлении</a>
        </div>
    </div>
    <div v-else-if="position === 'product'">
        <div class="product-vertical__preorder-text" v-if="variant.product.active === 2 || variant.product.active === 3">Последняя цена</div>
        <div class="variants-block">
            <transition name="fade">
                <div class="description" v-if="show_description">
                    <div v-html="variant_description"></div>
                    <a href="#" class="close" @click.prevent="closeDescription()"></a>
                </div>
            </transition>
            <div class="variants" :class="{'only-one': variants.length === 1}" v-if="variants.length > 0">
                <div :offset="variant_menu_offset" class="check" :width="variant_menu_item_with" :style="{'width': 'calc(' + variant_menu_item_with + '%)', 'transform': 'translateX(calc(' + variant_menu_item_with_calc + '%)'}"></div>
                <div class="item" v-for="(variant_menu, index) in variants" :key="variant_menu.id"  @click.prevent="changeVariant(index)">
                    <span v-if="variants.length > 2">
                        {{ variant_menu.unit.short_name }}
                    </span>
                    <span v-else>
                        {{ variant_menu.unit.name }}
                    </span>
                    <i class="far fa-question-circle" v-if="variant_menu.unit.short_description" @click.prevent="openDescription(index)"></i>
                </div>
            </div>
        </div>
        <div class="quantity-block">
            <div class="quantity-text" v-if="variant.quantity > 1 && quantity === variant.quantity">Максимальное количество на складе</div>
            <div class="quantity-text" v-else>Количество</div>
            <div class="quantity-price">
                <div class="overlay">
                    <div class="quantity">
                        <div class="buttons">
                            <a href="#" class="minus" @click.prevent="minusQuantity()" v-if="quantity > 1">-</a>
                            <a href="#" class="minus disable" @click.prevent="" v-else>-</a>
                            <span class="num">{{ quantity }}</span>
                            <a href="#" class="plus" @click.prevent="plusQuantity()" v-if="variant.quantity > 0 && quantity < variant.quantity">+</a>
                            <a href="#" class="plus disable" @click.prevent="" v-else-if="variant.quantity > 0 && quantity === variant.quantity">+</a>
                            <a href="#" class="plus" @click.prevent="plusQuantity()" v-else>+</a>
                        </div>
                    </div>
                </div>
                <div class="overlay">
                    <div class="price">
                        <div class="price__old" v-if="variant.product.discount > 0">{{ variant.price * quantity }}&#8381;</div>
                        <div class="price__now" :class="{'red': variant.product.discount > 0}">{{ variant.total_price * quantity }}</div>
                        <div class="price_currency" :class="{'red': variant.product.discount > 0}">&#8381;</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="variant.product.active === 1">
            <buy-button :product_id="variant.product_id" :variant_id="variant.id" :quantity="quantity" :name="variant.product.name" :unit_name="variant.unit.short_name" :price="variant.price" :total_price="variant.total_price" :discount="variant.product.discount" :discount_type="variant.product.discount_type" :image_url="variant.product.mainimage" :slug="variant.product.slug"></buy-button>
        </div>
        <div class="product-vertical__preorder" v-if="variant.product.active === 2 || variant.product.active === 3">
            <a href="#" class="green-bordered-button" @click.prevent="showModal()"><i class="far fa-bell"></i> Сообщить о поступлении</a>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                quantity: 1,
                variant: [],
                isLoading: false,
                variant_menu_item_with: 0,
                variant_menu_item_with_calc: 0,
                variant_menu_offset: 0,
                variant_description: null,
                show_description: false
            };
        },
        props: {
            variants: Array,
            position: String,
        },
        created() {
            this.variant = this.variants[0];
            this.variant_menu_item_with = 100 / this.variants.length;
            this.variants.forEach((value, index) => {
                if (value.is_default === 1) {
                    this.changeVariant(index);
                }
            });
        },
        methods: {
            changeVariant(index) {
                this.variant_menu_item_with_calc = index * 100;
                this.variant_menu_offset = index;
                this.variant = this.variants[index];
                this.quantity = 1;
            },
            plusQuantity() {
                this.quantity++;
            },
            minusQuantity() {
                this.quantity = this.quantity - 1;
            },
            openDescription(index) {
                this.variant_description = this.variants[index].unit.short_description;
                this.show_description = true;
                setTimeout(() => {
                    this.closeDescription();
                }, 10000);
            },
            closeDescription() {
                this.show_description = false;
            },
            showModal() {
                this.$store.dispatch('Shop/showModalProductPreorder', this.variant.product.name);
            },
        }
    }
</script>
