<template>
    <div class="cat">
        <div class="row category-params-row no-gutters">
            <div class="col-6">
                <!--<div id="products-filter">
                    <div class="filter-mobile-btn">
                        <a href="#" class="grey-bordered-button" :class="{active: showDropDown}" @click.prevent="dropdown()">Применить фильтры</a>
                    </div>
                    <div class="dropdown" v-if="showDropDown">
                        <div class="inner">

                        </div>
                    </div>
                </div>-->
                <div class="category-count">
                    <span v-if="total_products > 0">{{ total_products }} {{ countProdsTitle }}</span>
                </div>
            </div>
            <div class="col-6">
                <!--<div class="sort">
                    <ul>
                        <li class="sort-title">Сортировать по:</li>
                        <li><a href="#" :class="{active: sorting.name === 'sort'}" @click.prevent="changeSort('sort')">по-умолчанию <i class="fas" :class="{'fa-sort-amount-up': sorting.name === 'sort' && sorting.direction === 'asc', 'fa-sort-amount-down': sorting.name === 'sort' && sorting.direction === 'desc'}"></i></a></li>
                        <li class="last"><a href="#" :class="{active: sorting.name === 'price'}" @click.prevent="changeSort('price')">по цене <i class="fas" :class="{'fa-sort-amount-up-alt': sorting.name === 'price' && sorting.direction === 'asc', 'fa-sort-amount-down-alt': sorting.name === 'price' && sorting.direction === 'desc'}"></i></a></li>
                    </ul>
                </div>-->
                <div class="pages" v-if="last_page > 1">
                    Страница {{ current_page }} из {{ last_page }}
                </div>
            </div>
        </div>
        <div class="row" id="products-list-top">
            <div class="col-12 col-sm-6 col-lg-4 col-xxl-4" :class="{productverticalcol: index > 2}" v-for="(product,index) in products" :key="product.id">
                <div class="product-vertical nomargin" :class="{'preorder': product.active === 2}">
                    <div class="product-vertical__image">
                        <a :href="'/product/' + category_slug + '/' + product.slug" v-if="category_slug">
                            <img :src="'/storage/' + product.mainimage" v-if="product.mainimage" :class="{'preorder': product.active === 2}">
                            <img src="/images/frontend/no-image.png" v-else>
                        </a>
                        <a :href="'/product/' + product.slug" v-else>
                            <img :src="'/storage/' + product.mainimage" v-if="product.mainimage" :class="{'preorder': product.active === 2}">
                            <img src="/images/frontend/no-image.png" v-else>
                        </a>
                        <div class="sticker liquidation" v-if="product.discount > 49"><i class="fas fa-percentage"></i> Ликвидация</div>
                        <div class="sticker hot" v-else-if="product.discount > 29 || (product.discount > 24 && product.main_category_id === 26) || (product.discount > 24 && product.main_category_id === 34) || (product.discount > 24 && product.main_category_id === 36)"><i class="fas fa-fire"></i> Горячая цена</div>
                        <div class="sticker exclusively" v-else-if="product.discount < 30 && product.active === 1 && product.is_exclusively === 1"><i class="fas fa-crown"></i> Эксклюзивно</div>
                        <div class="sticker best" v-else-if="product.discount < 30 && product.active === 1 && product.is_best === 1"><i class="fas fa-kiss-wink-heart"></i> Рекомендуем</div>
                        <div class="sticker hit" v-else-if="product.discount < 30 && product.active === 1 && product.is_hit === 1"><i class="fas fa-star"></i> Хит продаж</div>
                        <div class="sticker new" v-else-if="product.discount < 30 && product.active === 1 && product.is_new === 1"><i class="fas fa-leaf"></i> Новинка</div>
                        <div class="sticker antiprice" v-else-if="product.discount < 30 && product.active === 1 && product.is_antiprice === 1"><i class="fas fa-thumbs-up"></i> Держим цену</div>
                        <div class="sticker preorder" v-else-if="product.active === 2"><i class="fas fa-sad-tear"></i> Закончился</div>
                    </div>
                    <div class="product-vertical__type">{{ product.type.name }}</div>
                    <div class="product-vertical__name" v-if="product.short_name">
                        <a :href="'/product/' + category_slug + '/' + product.slug" v-if="category_slug">{{ product.short_name }}</a>
                        <a :href="'/product/' + product.slug" v-else>{{ product.short_name }}</a>
                    </div>
                    <div class="product-vertical__name" v-else>
                        <a :href="'/product/' + category_slug + '/' + product.slug" v-if="category_slug">{{ product.name }}</a>
                        <a :href="'/product/' + product.slug" v-else>{{ product.name }}</a>
                    </div>
                    <div class="product-vertical__desc" v-if="category_slug">
                        {{ product.short_description_truncated }}. <a :href="'/product/' + category_slug + '/' + product.slug">Подробнее...</a>
                    </div>
                    <div class="product-vertical__desc" v-else>
                        {{ product.short_description_truncated }}. <a :href="'/product/' + product.slug">Подробнее...</a>
                    </div>
                    <variants :variants="product.variants" :position="'category'"></variants>
                    <a href="/favorite" class="product-vertical__favorite">
                        <svg>
                            <use xlink:href="/images/frontend/icons.svg#favorite" />
                        </svg>
                    </a>
                    <div class="product-vertical__sales" v-if="product.active === 1 && product.discount > 0 && product.discount_type === 'percents'">
                        <div class="product-sale" :class="{red: product.discount > 29 || (product.discount > 19 && product.main_category_id === 26) || (product.discount > 19 && product.main_category_id === 34) || (product.discount > 19 && product.main_category_id === 36), yellow: ((product.discount > 0 && product.discount < 30 && product.main_category_id !== 26 && product.main_category_id !== 34 && product.main_category_id !== 36) || (product.discount > 0 && product.discount < 20 && product.main_category_id === 26) || (product.discount > 0 && product.discount < 20 && product.main_category_id === 34) || (product.discount > 0 && product.discount < 20 && product.main_category_id === 36)), blackred: product.discount > 49}">-{{ product.discount }}%</div>
                    </div>
                    <div class="product-vertical__sales" v-if="product.active === 1 && product.discount > 0 && product.discount_type === 'numbers'">
                        <div class="product-sale">-{{ product.discount }}&#8381;</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="last_page > current_page">
                <div class="more-products-btn">
                    <a href="#" @click.prevent="showMoreProducts()" class="grey-button">Показать еще...</a>
                </div>
            </div>
        </div>
        <div class="row pagination-row">
            <div class="col-12 col-lg-6">
                <div class="pagination bottom">
                    <ul>
                        <li v-for="n in last_page">
                            <span v-if="n === current_page">{{ n }}</span>
                            <a href="#" @click.prevent="changePage(n, true, true)" v-else>{{ n }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="count-products">Вы посмотрели {{ to }} из {{ total_products }} товаров</div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                products: [],
                current_page: 1,
                next_page: 1,
                last_page: 1,
                total_products: 0,
                loading: false,
                filter: [],
                showDropDown: false,
                sorting: {
                    name: 'sort',
                    direction: 'desc'
                },
                from: 0,
                to: 0
            };
        },
        props: {
            category_id: Number,
            category_slug: [Number, String],
            sales: [Number, String],
            search: String
        },
        mounted() {
            if (this.$route.query.page) {
                this.changePage(this.$route.query.page, false, false);
            } else {
                this.changePage(1, false, false);
            }
        },
        methods: {
            //TODO реализовать фильтр
            dropdown() {
                this.showDropDown = !this.showDropDown;
            },
            //TODO реализовать сортировку
            changeSort(name) {
                this.sorting.name = name;
                if (this.sorting.direction === 'desc') {
                    this.sorting.direction = 'asc';
                } else {
                    this.sorting.direction = 'desc';
                }
            },
            showMoreProducts() {
                this.loading = true;
                this.$store.dispatch('Shop/showGlobalLoading');
                axios
                    .get('/api/frontend/products/get', {
                        params: {
                            "paginate": 15,
                            "category_id": this.category_id,
                            "page": this.next_page,
                            "sorting_name": this.sorting.name,
                            "sorting_direction": this.sorting.direction,
                            "sales": this.sales,
                            "search": this.search,
                            "with_preorder": 1
                        }
                    })
                    .then((response) => {
                        this.current_page = response.data.current_page;
                        this.next_page = response.data.current_page + 1;
                        this.last_page = response.data.last_page;
                        this.total_products = response.data.total;
                        this.to = response.data.to;
                        this.from = response.data.from;
                        $.each(response.data.data, (key, value) => {
                            this.products.push(value);
                        });
                        this.$router.push({
                            query: {
                                page: response.data.current_page
                            }
                        });
                        this.loading = false;
                        this.$store.dispatch('Shop/hideGlobalLoading');
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            changePage(page_num, page_router = true, scroll = false) {
                this.loading = true;
                this.$store.dispatch('Shop/showGlobalLoading');
                axios
                    .get('/api/frontend/products/get', {
                        params: {
                            "paginate": 15,
                            "category_id": this.category_id,
                            "page": page_num,
                            "sorting_name": this.sorting.name,
                            "sorting_direction": this.sorting.direction,
                            "sales": this.sales,
                            "search": this.search,
                            "with_preorder": 1
                        }
                    })
                    .then((response) => {
                        this.current_page = response.data.current_page;
                        this.next_page = response.data.current_page + 1;
                        this.last_page = response.data.last_page;
                        this.total_products = response.data.total;
                        this.products = response.data.data;
                        this.to = response.data.to;
                        this.from = response.data.from;
                        if (page_router === true) {
                            this.$router.push({
                                query: {
                                    page: response.data.current_page
                                }
                             });
                        }
                        this.loading = false;
                        this.$store.dispatch('Shop/hideGlobalLoading');
                        if (scroll === true) {
                            document.getElementById('products-list-top').scrollIntoView();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        computed: {
            countProdsTitle() {
                let number = this.total_products;

                let cases = [2, 0, 1, 1, 1, 2];
                let titles = ['товар', 'товара', 'товаров'];

                return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
            }
        }
    }
</script>
