<template>
    <div class="pickup-map">
        <h2>Выберите удобный для Вас пункт выдачи</h2>
        <div class="pickup-map-loading" v-if="pickup_loading == true"></div>
        <div id="map" style="height: 500px;"></div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                pickup_loading: true
            };
        },
        props: {
            price: [String, Number],
            date_start: String,
            date_end: String
        },
        mounted() {
            this.yandexMap();
        },
        methods: {
            async yandexMap() {
                let geo_lat = this.$store.state.Shop.location.data.geo_lat,
                    geo_lon = this.$store.state.Shop.location.data.geo_lon,
                    boxberry_id = this.$store.state.Shop.location.delivery.boxberry_id,
                    cdek_id = this.$store.state.Shop.location.delivery.cdek_id,
                    points,
                    city_name,
                    vm = this;

                if (this.$store.state.Shop.location.data.settlement != null) {
                    city_name = this.$store.state.Shop.location.data.settlement;
                } else {
                    city_name = this.$store.state.Shop.location.data.city;
                }

                await axios.get('/api/helpers/pickup/points/get', {
                    params: {
                        'boxberry_id': boxberry_id,
                        'cdek_id': cdek_id,
                        'city_name': city_name
                    }
                })
                    .then((response) => {
                        points = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });

                ymaps.ready(function() {
                    let myMap = new ymaps.Map("map", {
                            center: [geo_lat, geo_lon],
                            zoom: 12,
                            controls: ['zoomControl', 'searchControl'],
                        },
                        {suppressMapOpenBlock: true});


                    let geoObjects = [];

                    let BalloonContentLayout = ymaps.templateLayoutFactory.createClass(
                        '<div class="pickup-map-ballon">' +
                        '<div class="text">' +
                        '<div class="company">{{properties.company}}</div>' +
                        '<div class="address">{{properties.address}}</div>' +
                        '<div class="date_issue">Придет в пункт выдачи {{properties.date_start}} - {{properties.date_end}}, {{properties.price}} &#8381;</div>' +
                        '<div class="worktime">{{properties.schedule}}</div>' +
                        '</div>' +
                        '<div class="tripdescription">' +
                        '<div class="title">Как добраться</div>' +
                        '<div class="text">{{properties.description}}</div>' +
                        '</div>'+
                        '<div><button id="pickup-map-ballon-button-change" data-id="{{properties.id}}">Выбрать</button></div>' +
                        '</div>', {
                            build: function () {
                                BalloonContentLayout.superclass.build.call(this);

                                //let button = document.getElementById("pickup-map-ballon-button-change");
                                let button = this.getElement().querySelector('button');
                                button.addEventListener("click", this.onCounterClick);
                            },
                            clear: function () {
                                //let button = document.getElementById("pickup-map-ballon-button-change");
                                let button = this.getElement().querySelector('button');
                                button.removeEventListener("click", this.onCounterClick);

                                BalloonContentLayout.superclass.clear.call(this);
                            },
                            onCounterClick: function () {
                                vm.choosePickupPoint(this.getAttribute('data-id'));
                            }
                        });

                    let clusterer = new ymaps.Clusterer({
                        clusterBalloonContentLayout: 'cluster#balloonAccordion',
                        clusterBalloonItemContentLayout: BalloonContentLayout,
                        clusterBalloonPanelMaxMapArea: 0
                    });

                    for (let item of points) {
                        let company = '';
                        let balloon_style = 'islands#redDotIcon';
                        if (item.company === 'boxberry') {
                            company = 'Boxberry';
                            balloon_style = 'islands#redDotIcon';
                        } else if (item.company === 'cdek') {
                            company = 'СДЭК';
                            balloon_style = 'islands#darkGreenDotIcon';
                        } else if (item.company === 'pickpoint') {
                            company = 'Pickpoint';
                            balloon_style = 'islands#brownDotIcon';
                        }
                        let placemark = new ymaps.Placemark([item.latitude, item.longitude], {
                            balloonContentHeader: company,
                            id: item.id,
                            name: item.name,
                            address: item.address,
                            phone: item.phone,
                            description: item.tripdescription,
                            schedule: item.workshedule,
                            longitude: item.longitude,
                            latitude: item.latitude,
                            company: company,
                            iconCaption: company,
                            price: vm.price,
                            date_start: vm.date_start,
                            date_end: vm.date_end
                        }, {
                            balloonContentLayout: BalloonContentLayout,
                            balloonPanelMaxMapArea: 0,
                            preset: balloon_style
                        });
                        geoObjects.push(placemark);
                    }
                    clusterer.add(geoObjects);
                    myMap.geoObjects.add(clusterer);
                });

                this.pickup_loading = false;
            },
            choosePickupPoint(point_id) {
                this.pickup_loading = true;
                axios.get('/api/helpers/pickup/point/get', {
                    params: {
                        'point_id': point_id,
                    }
                })
                    .then((response) => {
                        this.pickup_loading = false;
                        this.$emit('currentPoint', response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
        }
    }
</script>
<style>
    [class*="ymaps-2"][class*="-ground-pane"] {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
        /* Firefox 3.5+ */
        -webkit-filter: grayscale(100%);
        /* Chrome 19+ & Safari 6+ */
    }
</style>