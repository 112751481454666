<template>
    <div class="pay">
        <form name="TinkoffPayForm" class="TinkoffPayForm" onsubmit="pay(this); return false;">
            <input class="tinkoffPayRow" type="hidden" name="terminalkey" value="1525683280902">
            <input class="tinkoffPayRow" type="hidden" name="frame" value="false">
            <input class="tinkoffPayRow" type="hidden" name="language" value="ru">
            <input class="tinkoffPayRow" type="hidden" name="reccurentPayment" value="false">
            <input class="tinkoffPayRow" type="hidden" name="customerKey" value="">
            <input class="tinkoffPayRow" type="hidden" placeholder="Сумма заказа" name="amount" required v-model="amount">
            <input class="tinkoffPayRow" type="hidden" placeholder="Номер заказа" name="order" v-model="order_id">
            <input class="tinkoffPayRow" type="hidden" placeholder="Описание заказа" name="description">
            <input class="tinkoffPayRow" type="hidden" placeholder="ФИО плательщика" name="name">
            <input class="tinkoffPayRow" type="hidden" placeholder="E-mail" name="email">
            <input class="tinkoffPayRow" type="hidden" placeholder="Контактный телефон" name="phone">
            <input class="tinkoffPayRow" type="submit" value="Оплатить заказ">
        </form>
    </div>
</template>
<script>
    export default {
        props: {
            order_id: Number,
            amount: Number,
        },
        mounted() {
            let externalScript = document.createElement('script');
            externalScript.setAttribute('src', 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js');
            document.head.appendChild(externalScript);
        }
    };
</script>
<style>
    .TinkoffPayForm {
        display:block;
        text-align: center;
        margin-bottom: 2rem;
    }
    .TinkoffPayForm input[type=submit] {
        display: inline-block;
        width: 200px;
        border-radius: 2px;
        border: 1px solid #1D9C56;
        text-align: center;
        padding: 1rem 1rem;
        text-decoration: none;
        background: #1D9C56;
        color: #fff;
        font-size: 1.125rem;
        cursor: pointer;
    }
</style>