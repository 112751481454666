<template>
    <div id="modalAddCart" :class="{'active': name}">
        <div class="outter">
            <div class="title">Товар добавлен в корзину!</div>
            <!--<div class="content">
                <div class="image">
                    <img :src="'/storage/' + image_url" alt="" v-if="image_url">
                    <img src="/images/frontend/no-image.png" alt="" v-else>
                </div>
                <div class="text">
                    <div class="name">{{ name }}</div>
                    <div class="quantity">{{ quantity }} x {{ unit_name }}</div>
                    <div class="summ">{{ quantity * total_price }} &#8381;</div>
                </div>
                <div style="clear: both;"></div>
            </div>-->
        </div>
    </div>
</template>
<script>
    export default {
        name: 'modal-add-cart',
        props: ['name', 'unit_name', 'quantity', 'total_price', 'image_url'],
    };
</script>