<template>
    <div class="show-modal-location" :class="{'active': show}">
        <div class="modal-overlay modal-location-overlay" @click.prevent="hideModalLocation"></div>
        <div class="modal modal-location">
            <a href="#" class="modal-close" @click.prevent="hideModalLocation"></a>
            <div class="modal-header">
                <div class="title">Город получения</div>
            </div>
            <div class="modal-body">
                <div class="search">
                    <div class="form-group">
                        <input type="text" class="input-text" name="location_name" placeholder="Введите город..." v-model="input" autocomplete="off">
                        <span class="loading" v-if="loading"></span>
                        <span class="desc">Введите Ваш город или населенный пункт и выберите его из списка.</span>
                    </div>
                    <div class="dropdown" :class="{active: dropdown}">
                        <ul>
                            <li v-for="(location, index) in locations">
                                <a href="#" @click.prevent="changeLocation(index)">
                                    <span class="city" v-if="location.data.settlement_with_type">{{ location.data.settlement_with_type }}</span>
                                    <span class="city" v-else-if="location.data.city_with_type">{{ location.data.city_with_type }}</span>
                                    <span class="region">{{ location.data.country }}, {{ location.data.region_with_type }}<span v-if="location.data.area_with_type">, {{ location.data.area_with_type }}</span></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer"></div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
          return {
              loading: false,
              locations: [],
              dropdown: false,
              input: null
          };
        },
        props: ['show'],
        watch: {
            input(newInput, oldInput) {
                this.debouncedSearchLocation();
            }
        },
        created() {
            this.debouncedSearchLocation = _.debounce(this.searchLocation, 500);
        },
        methods: {
            searchLocation() {
                this.dropdown = false;
                this.loading = true;
                if (this.input.length > 2) {
                    let vm = this;

                    this.loading = true;

                    axios('/api/dadata/location/get', {
                        params: {
                            search: this.input
                        }
                    })
                    .then((response) => {
                        if (response.data.data) {
                            vm.locations = [];
                            vm.locations.push(response.data);
                        } else {
                            vm.locations = response.data;
                        }
                        vm.dropdown = true;
                        vm.loading = false;
                    })
                    .catch(error => {
                        //console.log(error);
                    });
                } else {
                    this.loading = false;
                }
            },
            async changeLocation(index) {
                let location = this.locations[index];
                let delivery = {
                    boxberry_id: null,
                    cdek_id: null,
                    dpd_id: null
                };

                this.dropdown = false;
                this.$store.dispatch('Shop/showGlobalLoading');

                await axios.get('/api/dadata/location/delivery/get', {
                        params: {
                            'kladr_id': location.data.kladr_id
                        }
                    })
                    .then((response) => {
                        if (response.data.boxberry_id != null && response.data.boxberry_id.length > 0) {
                            delivery.boxberry_id = response.data.boxberry_id;
                        }
                        if (response.data.cdek_id != null && response.data.cdek_id.length > 0) {
                            delivery.cdek_id = response.data.cdek_id;
                        }
                        if (response.data.dpd_id != null && response.data.dpd_id.length > 0) {
                            delivery.dpd_id = response.data.dpd_id;
                        }
                        Vue.set(location, 'delivery', delivery);
                    })
                    .catch(error => {
                        console.log(error);
                    });

                window.localStorage.setItem('location', JSON.stringify(location));
                window.location.reload();
            },
            hideModalLocation() {
                this.$store.dispatch('Shop/hideModalLocation');
            }
        }
    }
</script>