<template>
    <a href="#" @click.prevent="showModalLocation()">{{ location }}</a>
</template>
<script>
    export default {
        data() {
            return {
               location: null
            };
        },
        watch: {
            //
        },
        mounted() {
            this.getLocation();
        },
        methods: {
            getLocation() {
                if (this.$store.state.Shop.location.data.settlement) {
                    this.location = this.$store.state.Shop.location.data.settlement;
                } else {
                    this.location = this.$store.state.Shop.location.data.city;
                }
            },
            showModalLocation() {
                this.$store.dispatch('Shop/showModalLocation');
            }
        }
    }
</script>