<template>
    <div class="pointmap">
        <div id="pointmap"></div>
        <div class="overlay"></div>
    </div>
</template>
<script>
    export default {
        props: {
            geo_lat: String,
            geo_lon: String,
        },
        mounted() {
            this.yandexMap();
        },
        methods: {
            async yandexMap() {
                let geo_lat = this.geo_lat,
                    geo_lon = this.geo_lon;
                ymaps.ready(function() {
                    let myMapPoint = new ymaps.Map("pointmap", {
                        center: [geo_lat, geo_lon],
                        zoom: 15,
                        controls: [],
                    }, {
                        suppressMapOpenBlock: true
                    });
                    let placemark = new ymaps.Placemark([geo_lat, geo_lon]);
                    myMapPoint.geoObjects.add(placemark);
                });
            }
        }
    }
</script>